import React, { useState } from 'react';
import CopyToClipboard from '../../components/CopyToClipboard';
import CallTranscriptRow from './CallTranscriptRow';

export const Superscript = ({children}) => {
    return <sup className="type-italics type-grey-1 type-left-side-bump-small">{children}</sup>
}

const CallTranscriptBlock = (props) => {
    const { lead } = props;

    const [expanded, setExpanded] = useState(false);

    const { lines = [], vendor = '' } = lead.call_transcript[0]
    let poweredByStatement = ''
    if (vendor && vendor !== '') {
        poweredByStatement = `Powered By ${vendor}`
    }

    const plainText = lines?.reduce((acc, line) => {
        acc += `${line.text}\n`
        return acc
    }, '')
    return lines && lines.length > 0 && (
        <div className="admin-call-transcript-block spacing-20-top">
            <p className="type-large-body type-heavy spacing-20-bottom">
                Call Transcript
                <Superscript>beta</Superscript>
                <span className="type-small-body type-italics type-left-side-bump type-grey">
                    {poweredByStatement}
                </span>
            </p>
            
            <div className={ `call-transcript-clipper ${expanded ? 'expanded' : ''}` }>
                <table className="call-transcript">
                    <tbody>
                        {lines.map((line, idx) => (
                            <CallTranscriptRow
                                key={idx}
                                timestamp={line.tStart}
                                transcriptText={line.text}
                                audioPlayerRef={ props.audioPlayerRef }
                                updateAudioPlaying={ props.updateAudioPlaying }
                                onClickTimestamp={ props.onClickTimestamp }
                            />
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="transcript-expansion-button">
                <span
                    role="button"
                    className="type-blue type-heavy type-small-body type-single-line"
                    onClick={ (e) => setExpanded(!expanded) }
                >
                    {expanded ? 'Close Transcript' : 'View Entire Transcript'}
                </span>
            </div>
            <div className="">
                <CopyToClipboard buttonText={"Copy Transcription Text"} dataToCopy={plainText}/>
            </div>
        </div>
    );
};

export default CallTranscriptBlock;
