import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { cloneDeep } from 'lodash'
import FullWidthLayout from '../../common/layouts/FullWidthLayout'
import Section, {
    SectionContent,
    SectionGrow,
    SectionContentCenter,
} from '../../common/layouts/Section'
import MajorAlerts from '../../common/MajorAlerts'
import CampaignService from '../../service/CampaignService'
import FilterInputs, { FiltersInputSkeleton } from './FilterInputs'
import {
    deleteQueryParams,
    getParamsFromSearch,
    mergeArraysByKey,
    updateQueryString,
} from './utils'
import PaginationSentence from '../../common/components/PaginationSentence'
import Button, { IconButton } from '../../common/components/form_elements/Button'
import MetricSentence from '../../common/components/MetricSentence'
import { PencilSvg } from '../../common/Svgs'
import AuthService from '../../service/AuthService'
import Modal from '../../common/Modal'
import BulkEditModal from './Modals/BulkEditModal'
import ConfirmationModal, { ConfirmationModalSkeleton } from './Modals/ConfirmationModal'
import EditsInProgressModal from './Modals/EditsInProgress'
import EditsErrorModal from './Modals/EditsErrorModal'
import MPCampaignsTable from './MPCampaignsTable'
import { Column, Row, RowSpaceBetween } from '../../common/layouts'
import SkeletonText from '../../common/components/Skeleton/SkeletonText'
import CopyToClipboard from '../../common/components/CopyToClipboard'
import ExportCSV from '../../common/components/ExportCSV'
// import DateRangeService from '../../service/DateRange'

const MarketplaceCampaignEditor = (props) => {
    const campaignService = new CampaignService()
    // defaults
    const NUM_ROWS_PER_PAGE = 50
    const DEFAULT_PAGINATION_NUM_ROWS = 100
    const DEFAULT_PAGINATION_OFFSET = 0
    const MAX_API_CALL_ITERATIONS = 10
    // MCO Rows
    const [mcoRows, setMcoRows] = useState([])
    const [allMcoRowIds, setAllMcoRowIds] = useState([])
    // const [selectedIds, setSelectedIds] = useState([])
    // const [selectSource, setSelectSource] = useState()
    const [paginationOffset, setPaginationOffset] = useState(DEFAULT_PAGINATION_OFFSET)
    const [paginationRows, setPaginationRows] = useState(DEFAULT_PAGINATION_NUM_ROWS)
    // MCO UI
    const [filters, setFilters] = useState([])
    const [defaultFilters, setDefaultFilters] = useState([])
    const [columns, setColumns] = useState([])
    const [summary, setSummary] = useState()
    const [modalContent, setModalContent] = useState()
    // loading indicators
    const [isLoadingUI, setIsLoadingUI] = useState(false)
    const [isLoadingRows, setIsLoadingRows] = useState(false)
    const [isLoadingMoreRows, setIsLoadingMoreRows] = useState(false)
    const [isUILoaded, setIsUILoaded] = useState(false)
    const [isSummaryLoading, setIsSummaryLoading] = useState(false)
    const [countApiIterations, setCountApiIterations] = useState(1)

    // get the URL parameters and initialize state with the data
    const history = useHistory()
    const { search = '' } = history.location
    // IMPORTANT - multiselect and filters need to be listed in the
    // arrayVals list below. This tells the function to treat these filter
    // values in the url as comma separated values so that they are set
    // in the filter correctly
    const urlParams = getParamsFromSearch(search, {
        arrayVals: [
            'campaign_ids',
            'contractor_ids',
            'industry_ids',
            'ad_network_ids',
            'ad_network2campaign_ids',
            'payout_model',
            'phone_leads_connected',
            'form_leads_connected',
            'client_campaign_status',
            'campaign_type',
            'lead_type',
            'sort_column',
            'sort_order'
        ],
    })
    const { sort_column = [], sort_order = [], metrics_date_from, metrics_date_to, ...rest } =
        urlParams
    // const [_sortColumn, setSortColumn] = useState(sort_column || DEFAULT_SORT_COLUMN)
    // const [_sortOrder, setSortOrder] = useState(sort_order || DEFAULT_SORT_ORDER)
    const [_sortColumn, setSortColumn] = useState(sort_column[0] || null)
    const [_sortOrder, setSortOrder] = useState(sort_order[0] || null)

    const initialCampaignIds = rest?.campaign_ids?.join(',')
    const [_campaignIds, setCampaignIds] = useState(initialCampaignIds)

    // initialize ad network 2 campaign ids with whatever is in the url
    let initialAdNetwork2CampaignIds = rest?.ad_network2campaign_ids?.join(',')
    const [_adNetwork2CampaignIds, setAdNetwork2CampaignIds] = useState(
        initialAdNetwork2CampaignIds
    )

    // default Metrics date range info
    // const DEFAULT_DATE_PRESET_ID = '8' // 30 days
    // const dateRangeService = new DateRangeService()
    // const { dateFrom: DEFAULT_METRICS_DATE_FROM, dateTo: DEFAULT_METRICS_DATE_TO } =
    //     dateRangeService.getFromToDates(DEFAULT_DATE_PRESET_ID)

    // keep track of the selected filters, initialize with default sort data
    // the sort order and sort columns are multiselect inputs being used in
    // single select mode, but the prop signature requires the selected values
    // to be arrays, so we put the sort data into arrays
    // const initialFilters = Object.assign({}, urlParams, {
        // sort_order: [_sortOrder],
        // sort_column: [_sortColumn],
        // metrics_date_from: metrics_date_from || DEFAULT_METRICS_DATE_FROM,
        // metrics_date_to: metrics_date_to || DEFAULT_METRICS_DATE_TO,
    // })
    const [selectedFilters, setSelectedFilters] = useState(urlParams)
    // keep track of the ad networks to edit
    const [adNetworkIdsToEdit, setAdNetworkIdsToEdit] = useState([])
    // this managed property manages the order in which the api endpoints
    // are queried for mco rows. the order is driven by the sort property,
    // so when the sort is changed, the query order may be updated
    const queryOrder = useRef([
        campaignService.getMarketplaceCampaignMetrics,
        campaignService.getMarketplaceCampaignSettings,
    ])

    // sort groups are set by the column definitions from the api
    const [sortGroups, setSortGroups] = useState({})

    // effect to set the document (page) title
    useEffect(() => {
        document.title = 'Marketplace Campaign Optimizer'
    }, [])

    // effect to fetch the UI data and Summary data
    useEffect(() => {
        if (!isLoadingUI) {
            setIsLoadingUI(true)
            // get the UI definition (columns, filters)
            campaignService
                .getMarketplaceCampaignUiDefinition()
                .then((response) => {
                    const { filters, columns, default_filters } = response.data
                    // filters
                    if (filters) {
                        setFilters(filters)
                    }

                    // default filters - if there are default filters, merge them
                    // into the selected filters. also save them for if the user
                    // clicks "reset filters"
                    if (default_filters) {
                        // const defaultFiltersClone = cloneDeep(default_filters)
                        setDefaultFilters(cloneDeep(default_filters))
                        setSelectedFilters(cloneDeep({ ...default_filters, ...selectedFilters }))
                        // if there isn't already a sort property, set the default
                        if (!_sortColumn) {
                            setSortColumn(default_filters.sort_column)
                        }
                        if (!_sortOrder) {
                            setSortOrder(default_filters.sort_order)
                        }
                    }

                    // colum definitions
                    let sort_groups
                    if (columns) {
                        setColumns(columns)

                        // get sorting groups from column definitions
                        sort_groups = columns.reduce((acc, col) => {
                            acc[col.property] = col.sort_group || 'unknown'
                            return acc
                        }, {})
                        setSortGroups(sort_groups)
                    }
                    if (sort_groups[_sortColumn] == 'settings') {
                        queryOrder.current = [
                            campaignService.getMarketplaceCampaignSettings,
                            campaignService.getMarketplaceCampaignMetrics,
                        ]
                    }
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    setIsLoadingUI(false)
                    setIsUILoaded(true)
                })

            // also kick off the summary query if there are values in the url
            if (search.replace('?', '') != '') {
                getSummaryData(search.replace('?', ''))
            }
        }
        // pass empty dependency array so that the effect is only called once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // effect to fetch the initial mco rows
    useEffect(() => {
        // load the data if the UI definitions have been received
        let query = search.replace('?', '')
        if (query == '') {
            setMcoRows([])
        } else if (isUILoaded == true) {
            setIsLoadingRows(true)
            loadData(query, paginationOffset, paginationRows)
                .then((rows, count) => {
                    setMcoRows(rows)
                    // if there are no rows, clear the summary data
                    if (!rows?.length || rows?.length == 0) {
                        setSummary(null)
                    }
                })
                .catch((err) => {
                    console.error(err)
                })
                .finally(() => {
                    setIsLoadingRows(false)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUILoaded])

    // effect to watch the number of api calls
    useEffect(() => {
        if (countApiIterations == 3) {
            toast('Still Fetching Results - Hang tight!', {
                type: 'info',
                pauseOnFocusLoss: false,
            })
        }
        if (countApiIterations == 6) {
            toast('Still Working on it!', { type: 'info', pauseOnFocusLoss: false })
        }
        if (countApiIterations == 9) {
            toast('Consider using a filter related to the sort property', {
                type: 'info',
                autoClose: false,
            })
        }
    }, [countApiIterations])

    /**
     * Initial data loader script
     * @param {string} query
     * @param {number} offset
     * @param {number} numRows
     * @param {boolean} fetchAllResults whether or not the query should exhause the complete
     * result set. Default is false
     * @returns
     */
    const loadData = (query, offset, numRows, fetchAllResults = false) => {
        return new Promise((resolve, reject) =>
            getData(
                resolve,
                reject,
                [], // initial rows
                1, // initial iteration count
                query,
                offset,
                numRows,
                fetchAllResults
            )
        )
    }

    /**
     * Recursive function to fetch MCO rows from the API. This function
     * will get rows until it meets one of the exit criteria. It manages
     * the pagination properties
     * @param {() => any} resolve
     * @param {() => any} reject
     * @param {array} rows
     * @param {number} i
     * @param {string} query
     */
    const getData = async (
        resolve,
        reject,
        rows,
        i,
        query,
        offset,
        numRows,
        fetchAllResults
    ) => {
        const { rows: newRows, endOfResults } = await getRows(query, offset, numRows)
        // add the new rows if they are valid
        if (newRows && newRows.length) {
            rows.push(...newRows)
        }
        // manage parameters
        i++
        offset += numRows
        // increase the number of requested rows
        // request at most 800 rows from the api
        numRows *= 2
        if (numRows > 800) {
            numRows = 800
        }
        // check for stop condition
        // if newRows is invalid
        // OR if the end of results is reached
        // OR the number of rows exceeds the min number of rows per page
        // OR stop after X iterations if desired
        if (
            newRows == null ||
            endOfResults ||
            (!fetchAllResults && rows?.length >= NUM_ROWS_PER_PAGE) ||
            (!fetchAllResults && i >= MAX_API_CALL_ITERATIONS)
        ) {
            // stash the pagination so its ready if the user clicks "load more"
            setPaginationOffset(offset)
            setPaginationRows(numRows)
            // reset the iteration count
            setCountApiIterations(1)
            resolve(rows, countApiIterations)
        } else {
            // otherwise, proceed with the iteration
            setCountApiIterations(i)
            getData(resolve, reject, rows, i, query, offset, numRows, fetchAllResults)
        }
    }

    /**
     * This function queries the api endpoints using the queryOrder,
     * which is set based on the sort property. The results are combined
     * with the intersection and returned
     * @param {string} query
     * @param {number} offset
     * @param {number} numRows
     * @returns
     */
    const getRows = (query, offset, numRows = 100) => {
        let sortedRows
        return queryOrder.current[0](
            `${query}&pagination[offset]=${offset}&pagination[rows]=${numRows}`
        )
            .then((response) => {
                const { rows, ad_network2campaign_ids } = response?.data
                // if the first response is empty, no need to proceed to the second response
                if (rows?.length == 0) {
                    return Promise.resolve({ data: { rows: [], endOfResults: true } })
                }
                // stash the response from the first query
                sortedRows = rows
                // then make the second query with ids from the first query
                // and omit the sort_column and sort_order
                // if the original query includes ad_network2campaign_ids, delete them
                // because they will be replaced by values from the first query
                const _q = deleteQueryParams(
                    query,
                    'sort_column',
                    'sort_order',
                    'ad_network2campaign_ids'
                )
                // and set pagination explicitly based on the first query results
                return queryOrder.current[1](
                    `${_q}&ad_network2campaign_ids=${ad_network2campaign_ids.join(
                        ','
                    )}&pagination[offset]=0&pagination[rows]=${
                        ad_network2campaign_ids.length
                    }`
                )
            })
            .then((response) => {
                const { rows, endOfResults } = response?.data
                // if the end of the results is reached, return accordingly
                if (endOfResults) {
                    return { rows: [], endOfResults: true }
                }
                // if query 2 has no results, just return an empty array
                if (!rows || rows?.length == 0) {
                    return { rows: [] }
                }
                // otherwise, merge results and return
                return {
                    rows: mergeArraysByKey(sortedRows, rows, 'ad_network2campaign_id', {
                        omitMissing: true,
                    }),
                }
            })
            .catch((err) => {
                console.error(err)
            })
    }

    /**
     * Convenience function to get summary data
     * @param {string} query
     */
    function getSummaryData(query) {
        setIsSummaryLoading(true)
        campaignService
            .getMarketplaceCampaignsSummary(query)
            .then((response) => {
                if (response.data) {
                    const { ad_network2campaign_ids, ...rest } = response.data
                    setAllMcoRowIds(ad_network2campaign_ids)
                    setSummary(rest)
                }
            })
            .catch((error) => {
                console.error(error)
                setSummary(null)
            })
            .finally(() => {
                setIsSummaryLoading(false)
            })
    }

    /**
     * Reset filters handler
     */
    const handleResetFilters = () => {
        setSelectedFilters(cloneDeep({...defaultFilters}))
        setCampaignIds('')
        setAdNetwork2CampaignIds('')
        // clear ad networks to edit
        setAdNetworkIdsToEdit([])
        // clear other state
        setMcoRows([])
        setSummary(null)
        setAllMcoRowIds(null)
        setCountApiIterations(0)
        // reset the sort properties
        setSortColumn(defaultFilters.sort_column)
        setSortOrder(defaultFilters.sort_order)
        // reset the url query
        const querystring = ''
        // update history
        history.push({
            search: `?${querystring}`,
        })
    }

    /**
     * Apply filters handler
     * @param {array} filters filters to be applied
     * @param {string[] | number[]} campaignIds optional, campaign ids
     * @param {string[] | number[]} an2cIds optional, adnetwork2campaign ids
     */
    const handleApplyFilters = (filters, campaignIds, an2cIds) => {
        // set selected filters
        setSelectedFilters(filters)
        setCampaignIds(campaignIds)
        setAdNetwork2CampaignIds(an2cIds)

        console.log(filters)
        // return
        // if sorting is set, also set the sort properties
        if (filters?.sort_column[0]) {
            setSortColumn(filters.sort_column[0])
            // check the property against the sort groups to determine
            // the query order
            if (sortGroups[filters.sort_column[0]] == 'settings') {
                queryOrder.current = [
                    campaignService.getMarketplaceCampaignSettings,
                    campaignService.getMarketplaceCampaignMetrics,
                ]
            } else {
                queryOrder.current = [
                    campaignService.getMarketplaceCampaignMetrics,
                    campaignService.getMarketplaceCampaignSettings,
                ]
            }
        }
        if (filters?.sort_order[0]) {
            setSortOrder(filters.sort_order[0])
        }

        // clear the current mco rows. set to null to force AG Grid to display
        // the Loading overlay
        setMcoRows([])

        // clear ad networks to edit
        setAdNetworkIdsToEdit([])
        // update URL
        const currentQuerystring = history.location.search
        const querystring = updateQueryString(currentQuerystring, {
            filters,
            campaignIds,
            adNetwork2CampaignIds: an2cIds,
        })
        // update history
        history.push({
            search: `?${querystring}`,
        })
        // reset pagination
        setPaginationOffset(DEFAULT_PAGINATION_OFFSET)
        setPaginationRows(DEFAULT_PAGINATION_NUM_ROWS)
        // request more data
        setIsLoadingRows(true)
        loadData(
            `${querystring}`,
            DEFAULT_PAGINATION_OFFSET,
            DEFAULT_PAGINATION_NUM_ROWS
        ).then((rows) => {
            // add the new rows to the existing rows
            setMcoRows(rows)
            setIsLoadingRows(false)
        })
        // also get new summary data
        getSummaryData(querystring)
    }

    /**
     * Handler when a table header is clicked
     * @param {string} property the property being sorted
     */
    const handleOrderBy = (property) => {
        let order = _sortOrder
        // if clicking the same column
        if (_sortColumn === property) {
            if (_sortOrder === 'asc') {
                order = 'desc'
            } else {
                order = 'asc'
            }
        } else {
            // if the column is new or different, set to desc
            order = 'desc'
        }
        setSortColumn(property)
        setSortOrder(order)

        // also update sort in the selectedFilters
        const newFilters = Object.assign({}, selectedFilters, {
            sort_column: [property],
            sort_order: [order],
        })
        setSelectedFilters(newFilters)

        // clear the current mco rows
        setMcoRows([])

        // check the property against the sort groups to determine
        if (sortGroups[property] == 'settings') {
            queryOrder.current = [
                campaignService.getMarketplaceCampaignSettings,
                campaignService.getMarketplaceCampaignMetrics,
            ]
        }

        // update the querystring
        const currentQuerystring = history.location.search
        const querystring = updateQueryString(currentQuerystring, {
            sortColumn: property,
            sortOrder: order,
        })

        // update history
        history.push({
            search: `?${querystring}`,
        })

        // get more data
        setIsLoadingRows(true)
        loadData(
            `${querystring}`,
            DEFAULT_PAGINATION_OFFSET,
            DEFAULT_PAGINATION_NUM_ROWS
        ).then((rows) => {
            // set the new rows
            setMcoRows(rows)
            setIsLoadingRows(false)
        })
    }

    /**
     * Load More button handler
     */
    const handleLoadMore = () => {
        setIsLoadingMoreRows(true)
        let querystring = history?.location?.search || ''
        // ok to use the default stop condition
        loadData(`${querystring.replace('?', '')}`, paginationOffset, paginationRows)
            .then((rows) => {
                // add the new rows to the existing rows
                setMcoRows([...mcoRows, ...rows])
            })
            .catch((err) => {
                console.err('err loading more', err)
            })
            .finally(() => {
                setIsLoadingMoreRows(false)
            })
    }

    /**
     * callback for row selection, passed to the table component
     * @param {number[]} selectedIds the campaign lead source ids that are selected
     * @param {string} source the source of the select action. source will be 
     * 'checkboxSelected' when a row is selected, and 'uiSelectAll' when the select 
     * all checkbox is checked
     */
    const handleSelectRows = useCallback((selectedIds, source) => {
        if (source == 'uiSelectAll') {
            if (selectedIds.length == 0) {
                setAdNetworkIdsToEdit([])
            } else {
                const adNetworkIdsOnScreen = mcoRows.reduce((acc, c) => {
                    acc.push(c.ad_network2campaign_id)
                    return acc
                }, [])
                const unselectedIds = adNetworkIdsOnScreen.filter(
                    (x) => !selectedIds.includes(x)
                    )
                // now remove the unselected ids from the list of adnetwork ids to edit
                const idsToEdit = allMcoRowIds.filter((v) => !unselectedIds.includes(v))
                setAdNetworkIdsToEdit(idsToEdit)
            }
        } else if (source == 'checkboxSelected') {
            setAdNetworkIdsToEdit(selectedIds)
        }
    }, [allMcoRowIds, mcoRows])

    /**
     * Hanlder for the Preview Edits button
     * on the MCO editor modal
     */
    const handlePreviewEdits = async (edits) => {
        const postBody = {
            ad_network2campaign_ids: adNetworkIdsToEdit,
            preview: true,
            edits,
        }
        const query = props?.history?.location?.search.replace('?', '') || ''
        setModalContent(<ConfirmationModalSkeleton />)
        try {
            const response = await campaignService.updateCampaignAdNetworks({
                query,
                body: postBody,
            })
            if (response.data) {
                setModalContent(
                    <ConfirmationModal
                        onCancel={handleCancelChanges}
                        onCompleteEdits={handleCompleteEdits}
                        onGoBack={handleBulkEditClick}
                        confirmation={response.data.confirmation}
                        edits={edits}
                        dataForClipboard={response.data.dataForClipboard}
                    />
                )
            }
        } catch (err) {
            console.log(err)
            setModalContent(<EditsErrorModal error={err} onClose={closeModalReload} />)
        }
    }

    /**
     * Hanlder for the Complete Edits button
     * on the MCO editor modal
     */
    const handleCompleteEdits = (edits) => {
        const postBody = {
            ad_network2campaign_ids: adNetworkIdsToEdit,
            preview: false,
            edits,
        }
        const query = props?.history?.location?.search.replace('?', '') || ''
        campaignService
            .updateCampaignAdNetworks({
                query,
                body: postBody,
            })
            .then((response) => {
                if (response.data) {
                    setModalContent(
                        <EditsInProgressModal
                            requestId={response.data.progressRequestedId}
                            handleEditsComplete={closeModalReload}
                            onCloseButton={() => setModalContent(null)}
                        />
                    )
                }
            })
            .catch((err) => {
                console.error(err)
                setModalContent(
                    <EditsErrorModal error={err} onClose={closeModalReload} />
                )
            })
    }

    /**
     * Handler for the Cancel Edits button
     */
    const handleCancelChanges = () => {
        setModalContent(null)
    }

    /**
     * Close Modal and reload
     */
    const closeModalReload = () => {
        setModalContent(null)
        window.location.reload()
    }

    /**
     * This function creates the context statement above the MCO table
     * @returns the JSX for the context statement
     */
    const getContextStatement = () => {
        const numberOfLoadedResults = mcoRows?.length || 0
        // if the summary is loading, show the skeleton text
        if (isSummaryLoading || isLoadingRows || isLoadingUI) {
            return <SkeletonText />
        }
        let result = (
            <PaginationSentence
                loading={isSummaryLoading || isLoadingRows}
                pageNum={1}
                rowsPerPage={numberOfLoadedResults}
                totalRows={summary?.total_rows || 0}
                label="Campaign Lead Source"
            />
        )
        if (adNetworkIdsToEdit?.length > 0) {
            const metric = adNetworkIdsToEdit.length
            result = (
                <span>
                    Selected{' '}
                    <MetricSentence metric={metric} label="Campaign Lead Source" />
                </span>
            )
        } else if (numberOfLoadedResults == 0 || (filters && columns?.length === 0)) {
            // case when filters are loaded but nothing else
            result = (
                <span className="type-heavy">
                    Apply Filters to View Campaign Lead Sources
                </span>
            )
        }
        return result
    }

    const handleBulkEditClick = () => {
        const count = adNetworkIdsToEdit.length
        setModalContent(
            <BulkEditModal
                onPreviewEdits={handlePreviewEdits}
                numEdits={count}
                onCancel={handleCancelChanges}
            />
        )
    }

    const handleCloseBulkEdit = () => {
        setModalContent(null)
    }

    const getBulkEditStatement = () => {
        const isBulkEditEnabled = AuthService.isAdmin && adNetworkIdsToEdit.length > 0
        return (
            <IconButton
                icon={PencilSvg}
                variant="span"
                onClick={handleBulkEditClick}
                disabled={!isBulkEditEnabled}
            >
                <span className={'type-heavy'}>Edit Selected Campaign Lead Sources</span>
            </IconButton>
        )
    }

    // exporting to csv is disabled while
    const exportCsvButtonDisabled =
        isLoadingUI ||
        isLoadingRows ||
        isSummaryLoading ||
        mcoRows?.length == 0

    // the "load more" button is disabled if:
    // the page is loading / reloading, OR
    // there are no campaigns, OR
    // the total number of ad networks equals the total number of rows per pagination
    const loadMoreButtonDisabled =
        isLoadingUI ||
        isLoadingRows ||
        isLoadingMoreRows ||
        mcoRows?.length == 0 ||
        mcoRows?.length + 1 >= summary?.total_rows

    const isLoadingData = isLoadingRows || isLoadingMoreRows

    const modalHeader = `Edit ${adNetworkIdsToEdit.length} Campaign Lead Sources`
    return (
        <FullWidthLayout>
            <Modal
                content={modalContent}
                header={modalHeader}
                onCloseButton={handleCloseBulkEdit}
                updateModalContent={() => setModalContent(null)}
                flat={true}
            />
            <MajorAlerts />
            <Section>
                {isLoadingUI ? (
                    <FiltersInputSkeleton />
                ) : (
                    <FilterInputs
                        loading={isLoadingUI}
                        reloading={isLoadingUI || isLoadingRows}
                        filters={filters}
                        initialCampaignIds={_campaignIds}
                        initialAdNetwork2CampaignIds={_adNetwork2CampaignIds}
                        selectedFilters={selectedFilters}
                        handleApplyFilters={handleApplyFilters}
                        handleResetFilters={handleResetFilters}
                    />
                )}
            </Section>
            <SectionGrow>
                <RowSpaceBetween>
                    <Column>
                        <SectionContent>{getContextStatement()}</SectionContent>
                        <SectionContent>{getBulkEditStatement()}</SectionContent>
                    </Column>
                    <Row></Row>
                </RowSpaceBetween>
                <MPCampaignsTable
                    loadingColumns={isLoadingUI}
                    loadingRows={isLoadingData}
                    mcoRows={mcoRows}
                    columns={columns}
                    sortColumn={_sortColumn}
                    sortOrder={_sortOrder}
                    onOrderBy={handleOrderBy}
                    onSelectRow={handleSelectRows}
                    summary={summary}
                />
                <div style={{ marginBottom: '20px' }}></div>
                <SectionContentCenter>
                    <Button disabled={loadMoreButtonDisabled} onClick={handleLoadMore}>
                        {isLoadingMoreRows || isLoadingRows ? 'Loading...' : 'Load More'}
                    </Button>
                </SectionContentCenter>
                <SectionContentCenter>
                    <ExportCSV
                        dataGetter={() => {
                            const querystring = history?.location?.search || ''
                            // load all data, don't paginate
                            // however, this will also mess with the offsets, so stash them
                            // here to reset them later
                            const _offset = paginationOffset
                            const _rows = paginationRows
                            return loadData(
                                `${querystring.replace('?', '')}`,
                                DEFAULT_PAGINATION_OFFSET,
                                DEFAULT_PAGINATION_NUM_ROWS,
                                true
                            ).then((rows) => {
                                setPaginationOffset(_offset)
                                setPaginationRows(_rows)
                                return rows
                            })
                        }}
                        downloadPrefix="mco_"
                        isDisabled={exportCsvButtonDisabled}
                    />
                </SectionContentCenter>
                <SectionContentCenter>
                    <CopyToClipboard
                        buttonText="Copy All Campaign Lead Source IDs"
                        dataToCopy={allMcoRowIds?.join(',') || ''}
                        disabled={!allMcoRowIds?.length > 0}
                    />
                </SectionContentCenter>
            </SectionGrow>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="light"
            />
        </FullWidthLayout>
    )
}

export default MarketplaceCampaignEditor
