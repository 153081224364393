import _startCase from 'lodash/startCase'
import type { IPaginationInterface } from "./Interfaces/IPaginationInterface";

export const upperAllFirst = (aString) => {
    // split the sentence into an array of words
    const words = aString.split(' ')

    // capitalize each word
    const updatedWords = words.map(upperFirst)

    // return the rebuilt sentence
    return updatedWords.join(' ')
}

export const upperFirst = (aString) => {
    return aString.charAt(0).toUpperCase() + aString.slice(1)
}

/**
 * Accepts a number and returns it formatted in a short format as follows:
 * when < 1,000 => number
 * when between 1,000 and 999,999 => 1.5k, 9.9k, 22.7k, 99.9k, etc.
 * when between >= 1 million => 1M, 5.5M, 72.4M, etc.
 *
 * Note that rounding also occurs.  E.g. 195,600 => 196K.
 * @param {number} number
 * @returns {string}
 */
export const formatNumberShort = (number) => {
    // number = 1000; // => 1K
    // number = 1001; // => 1K
    // number = 1371; // => 1.4K
    // number = 1999; // => ; // 2K
    // number = 32178; // => ; // 32.2K
    // number = 200090; // => ; // 200K
    // number = 298401; // => ; // 298K
    // number = 998721; // => ; // 999K
    // number = 10048291; // ; // => 10M
    // number = 10098291; // ; // => 10.1M

    let decimalPlaces = 1

    if (number === '') {
        return ''
    }

    if (number < 1000) {
        return number
    }

    let divisor = 1000,
        reducedNumber,
        suffix = 'K'

    if (number >= 1000000000) {
        divisor = 1000000000
        suffix = 'B'
    } else if (number >= 1000000) {
        divisor = 1000000
        suffix = 'M'
    } else if (number >= 100000) {
        decimalPlaces = 0
    }

    reducedNumber = number / divisor

    return reducedNumber.toFixed(decimalPlaces).replace(/\.0$/, '') + suffix
}
/**
 * Accepts a number and returns a shorter representation of it through an
 * object of this form:
 * {
 *     number: [the shortened number],
 *     suffix: ["K", "M", etc]
 * }
 *
 * Note that rounding also occurs.  E.g. 195,600 => 196K.
 * @param number
 * @returns {object}
 */
export const getShortNumber = (number) => {
    // number = 1000; // => 1K
    // number = 1001; // => 1K
    // number = 1371; // => 1.4K
    // number = 1999; // => ; // 2K
    // number = 32178; // => ; // 32.2K
    // number = 200090; // => ; // 200K
    // number = 298401; // => ; // 298K
    // number = 998721; // => ; // 999K
    // number = 10048291; // ; // => 10M
    // number = 10098291; // ; // => 10.1M

    let decimalPlaces = 1

    if (number === '' || number < 1000) {
        return {
            number: number,
            suffix: '',
        }
    }

    let divisor = 1000,
        reducedNumber,
        suffix = 'K'

    if (number >= 1000000000) {
        divisor = 1000000000
        suffix = 'B'
    } else if (number >= 1000000) {
        divisor = 1000000
        suffix = 'M'
    } else if (number >= 100000) {
        decimalPlaces = 0
    }

    reducedNumber = number / divisor

    return {
        number: reducedNumber.toFixed(decimalPlaces).replace(/\.0$/, ''),
        suffix: suffix,
    }
}

/**
 * Accepts a number, which is cast to a float to allow loosely typed values, and
 * returns number as a formatted currency string value.
 * @param {number|string} number
 * @param {number} [numberDecimals=2]
 * @returns {string}
 */
export const formatCurrency = (number, numberDecimals = 2) => {
    //
    // If number is a string, toLocalString() won't produce the expected
    // results.  E.g. "32" => 32, vs. 32 => $32.00.  We want the latter.
    //
    if (typeof number === 'string') {
        number = parseFloat(number)
    }

    if (isNaN(number) || number == null) {
        return ''
    }

    return number.toLocaleString('EN', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: numberDecimals,
        maximumFractionDigits: numberDecimals,
    })
}

export const formatNumber = (number, numberDecimals = 2) => {
    //
    // If number is a string, toLocalString() won't produce the expected
    // results.  E.g. "32" => 32, vs. 32 => $32.00.  We want the latter.
    //
    if (typeof number == 'string') {
        number = parseFloat(number)
    }

    if (isNaN(number) || number === null) {
        return ''
    }

    return number.toLocaleString('EN', {
        minimumFractionDigits: numberDecimals,
        maximumFractionDigits: numberDecimals,
    })
}

/**
 * Formats a datetime or timestamp string from YYYY-MM-DD to MM/DD/YYYY
 */
export const formatDate = (dateStr) => {
    if (!dateStr) {
        return ''
    }

    let datePieces = dateStr.substr(0, 10).split('-'),
        month = datePieces[1],
        day = datePieces[2],
        year = datePieces[0]

    return `${month}/${day}/${year}`
}

export const getCurrentDate = () => {
    let date = new Date()
    let month = date.getMonth() + 1

    if (month < 10) {
        month = '0' + month
    }

    let day = date.getDate()

    if (day < 10) {
        day = '0' + day
    }

    let year = date.getFullYear()

    return month + '/' + day + '/' + year
}

/**
 * Formats a datetime or timestamp string from MM/DD/YYYY to YYYY-MM-DD
 */
export const formatDateToYYYYMMDD = (dateStr) => {
    if (!dateStr) {
        return ''
    }

    let datePieces = dateStr.substr(0, 10).split('/'),
        month = datePieces[0],
        day = datePieces[1],
        year = datePieces[2]

    return year + '-' + month + '-' + day
}

export const getDateNDaysFromNow = (nDays) => {
    let newDate = new Date()
    newDate.setDate(newDate.getDate() + nDays)
    newDate = newDate.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    })
    return formatDateToYYYYMMDD(newDate)
}

export const getDate = (dbDateTime) => {
    if (!dbDateTime) {
        return ''
    }
    let dbDateTimePieces = dbDateTime.split(' ')
    return dbDateTimePieces[0]
}

/**
 * Returns a native Date object while replacing the empty space between the date and time with a 'T' to prevent a Safari bug
 * @param dateString
 * @return {Date}
 */
export function safeDate(dateString): Date {
    return new Date(dateString.replace(' ', 'T'))
}

export const separateTimeAndDate = (dbTimestamp) => {
    if (!dbTimestamp) {
        return ''
    }

    let dateTime = safeDate(dbTimestamp) // Reformat timestamp for Safari’s Date implementation
    //
    // If getTime() is NaN, then dateTime is not a valid date.
    //
    if (isNaN(dateTime.getTime())) {
        return ''
    }

    let date,
        time,
        month = dateTime.getMonth() + 1,
        day = dateTime.getDate(),
        hours = dateTime.getHours(),
        minutes = dateTime.getMinutes(),
        amPm = 'AM'

    if (month < 10) {
        month = '0' + month
    }

    if (day < 10) {
        day = '0' + day
    }

    if (hours >= 12) {
        amPm = 'PM'
    }
    if (hours > 12) {
        hours = hours - 12
    }

    if (minutes < 10) {
        minutes = '0' + minutes
    }

    date = month + '/' + day + '/' + dateTime.getFullYear()
    time = hours + ':' + minutes + ' ' + amPm

    return {
        date: date,
        time: time,
    }
}

/**
 * Convert a db timestamp into date and time, with options on the
 * returned data
 * @param {string} dbTimestamp 
 * @param {{ timeLeadingZero: boolean }} options 
 * @returns 
 */
export const formattedTimeAndDate = (dbTimestamp, options) => {
    if (!dbTimestamp) {
        return ''
    }

    let dateTime: Date = safeDate(dbTimestamp)

    //
    // If getTime() is NaN, then dateTime is not a valid date.
    //
    if (isNaN(dateTime.getTime())) {
        return ''
    }

    let date: string,
        time: Element,
        month = dateTime.getMonth() + 1,
        day = dateTime.getDate(),
        hours = dateTime.getHours(),
        minutes = dateTime.getMinutes(),
        amPm = 'am'

    if (month < 10) {
        month = '0' + month
    }

    if (day < 10) {
        day = '0' + day
    }

    if (hours >= 12) {
        amPm = 'pm'
    }
    if (hours > 12) {
        hours = hours - 12
    } else if (hours == 0) {
        hours = 12
    }

    // add a leading 0 for time
    if (options?.timeLeadingZero && hours < 10) {
        hours = `0${hours}`
    }

    if (minutes < 10) {
        minutes = '0' + minutes
    }

    date = `${month}/${day}/${`${dateTime.getFullYear()}`.substring(2)}`
    time = `${hours}:${minutes}`

    return {
        date,
        time,
        amPm,
    }
}

export const formatSecondsToHourMinute = (secondsIn) => {
    secondsIn = parseInt(secondsIn, 10)

    if (isNaN(secondsIn)) {
        return ''
    }

    let minutes = Math.floor(secondsIn / 60),
        seconds = secondsIn % 60

    if (seconds < 10) {
        seconds = '0' + seconds
    }

    return minutes + ':' + seconds
}

export const jsDateToSqlDateString = (date) => {
    let fromMonth = date.getMonth() + 1
    if (fromMonth < 10) {
        fromMonth = `0${fromMonth}`
    }
    let fromDay = date.getDate()
    if (fromDay < 10) {
        fromDay = `0${fromDay}`
    }
    return `${date.getFullYear()}-${fromMonth}-${fromDay}`
}

export const formatDateNamedMonth = (dateStr) => {
    const date = safeDate(dateStr)
    let month = date.toLocaleString('default', { month: 'short' })

    // month #4 is May, which does not require the '.' (e.g. Jun. but not May.)
    if (date.getMonth() !== 4) {
        month += '.'
    }

    return month + ' ' + date.getDate() + ', ' + date.getFullYear()
}

export function isDateWithinDaysFromToday(date, days) {
    if (!date || !days) {
        throw Error('please provide a date and number of days to check')
    }
    const today = new Date();
    const givenDate = new Date(date);
    
    // Calculate the difference in milliseconds
    const diff = givenDate - today;
    
    // Convert the difference to days
    const diffInDays = diff / (1000 * 60 * 60 * 24);
    
    // Check if the difference is less than 10 days
    return diffInDays <= days && diffInDays >= 0;
  }

export const isObject = (object: any): boolean =>
    object === Object(object) && !Array.isArray(object) && typeof object !== 'function'

export const toCamel = (str: string): string =>
    str.replace(/(_\w)/g, (match) => match[1].toUpperCase())
export const toCamelObject = (variable: any, recursive: boolean = true): Object => {
    if (isObject(variable)) {
        let result = {}
        Object.keys(variable).forEach((key) => {
            result[toCamel(key)] = recursive
                ? toCamelObject(variable[key])
                : variable[key]
        })

        return result
    } else if (Array.isArray(variable)) {
        return variable.map((item) => toCamelObject(item))
    }

    return variable
}
export const toSnake = (string: string): string =>
    string.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

export const toTitleCase = (string) => {
    return _startCase(string)
}

export const toSnakeObject = (variable: any): Object => {
    if (isObject(variable)) {
        let result = {}
        Object.keys(variable).forEach((key) => {
            result[toSnake(key)] = toSnakeObject(variable[key])
        })

        return result
    } else if (Array.isArray(variable)) {
        return variable.map((item) => toSnakeObject(item))
    }

    return variable
}

/**
 * Masks the credit card's expiration date field to MM/YY format.
 * @param {KeyboardEvent} event
 * @return {string}
 */
export const maskExpDate = (event: KeyboardEvent) => {
    let currentDate = event.target.value,
        currentLength = currentDate.length,
        // these are keycodes that we don't want to interrupt, b/c the user
        // should be free to enter these w/out odd behavior.  E.g. the
        // Backspace key should be allowed so that the user can remove
        // character.  And I found I needed to manually enter the forward
        // in a particular case (that I can't remember exactly), so we'll
        // allow that too, among the others.
        permittedKeyCodes = [
            8, // Backspace
            46, // Delete
            191, // Forward slash,
            37, // left arrow key
            38, // up key
            39, // right arrow key
            40, // down arrow key
        ]

    //
    // 1 Exception to the "permittedKeyCodes" - when you've typed e.g. "1/",
    // we will prefix the 0, giving "01/" and preventing a validation error.
    // Note that because "/" is a permittedKeyCode, this requires us to make
    // and exception to the permittedKeyCodes logic following this.
    //
    if (event.key == '/' && currentLength == 2) {
        return '0' + currentDate
    }

    if (permittedKeyCodes.indexOf(event.keyCode) !== -1) {
        return currentDate
    }

    //
    // If we receive a non-numeric, non permittedKeycode, then return a new
    // value w/the non-numeric input we just received, stripped.
    //
    if (isNaN(parseInt(event.key, 10))) {
        let pattern = event.key,
            regex = new RegExp(pattern, 'g')

        return currentDate.replace(regex, '')
    }

    // If the user's entered a month value greater than 1, we can auto
    // complete by prepending a 0 and appending a /.
    if (currentLength == 1 && currentDate > 1) {
        return '0' + currentDate + '/'
    }
    // otherwise if there's 2 digits, just append the slash, but only if
    // a slash isn't already present.  I encountered a case where 2 slashes
    // wound up in my date string, so this should prevent similar cases.
    else if (currentLength == 2 && currentDate.indexOf('/') === -1) {
        return currentDate + '/'
    }

    return currentDate
}

/**
 * validates a given zip code
 * @param {string} zipCode
 * @param {string} [acceptedZipCodeTypes = 'USA'] if set to 'USA', will validate USA zip codes
 *  if set to 'CAN', will validate Canadian postal codes
 *  if set to 'both', will validate either Canadian or USA zips
 * @return {boolean} true iff the zip code is valid
 */
export function isValidZipCode(zipCode: string, acceptedZipCodeTypes: string = 'USA') {
    acceptedZipCodeTypes = acceptedZipCodeTypes.toUpperCase()

    switch (acceptedZipCodeTypes) {
        case 'USA':
            return isValidAmericanZip(zipCode)
        case 'CAN':
            return isValidCanadianZip(zipCode)
        case 'BOTH':
            return isValidAmericanZip(zipCode) || isValidCanadianZip(zipCode)
        default:
            throw new Error(
                `Invalid acceptedZipCodeTypes provided: ${acceptedZipCodeTypes}`
            )
    }
}

export function isValidAmericanZip(zipCode: string) {
    const USA_ZIP_PATTERN = /^\d{5}$/
    return USA_ZIP_PATTERN.test(zipCode)
}

/**
 * checks if a zip code is a valid Canadian postal code
 * @param zipCode
 * @return {boolean}
 */
export function isValidCanadianZip(zipCode: string) {
    const CANADA_ZIP_REGEX = /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?([0-9][A-Z][0-9])?$/i
    return CANADA_ZIP_REGEX.test(zipCode)
}

/**
 * parses regular expressions special characters in a string
 * e.g. "domain.com/?kvp" => "domain\.com\/\?kvp"
 * @param {string} value - the targeted string
 * @return {string} the parsed string
 */
export function regExpEscape(value: string) {
    return value.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
}

export function isInteger(value) {
    return parseInt(value) == value
}

export function isOnlyNumbers(val) {
    return /^[0-9]+$/.test(val)
}

/**
 * Deep clones a variable
 * We have this function because the spread operator {...source} performs a shallow clone
 * See the note here: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_syntax#copy_an_array
 *
 * Note: this clone function will only clone primitive properties.
 *  We should probably upgrade it in the future or use a library
 *
 * @param {any} source
 * @return {any}
 */
export function clone(source) {
    return JSON.parse(JSON.stringify(source))
}

export const sdAdminUrl = process.env.REACT_APP_SD_ADMIN_URL || 'https://my.servicedirect.com/admin/';

/**
 * Build list of links for User column contextual menu on mySD Admin user manager
 * @param {number} userId
 * @param {string} username
 * @param {string} password
 * @param {number} adminId
 * @return {{label: string, url: string}[]}
 */
export function getUserMenuLinks(userId: number, username: string, password: string, adminId: number) {
    return [
        {
            label: 'Edit User',
            url: ``,
        },
        {
            label: 'Log In as User',
            url: `/?username=${username}&pass_word=${password}&vicarious_admin_id=${adminId}`,
        },
        {
            label: 'Open in DB Viewer',
            url: `${sdAdminUrl}db_viewer?sql=select%20*%20from%20user%20where%20user_id=${userId}`,
        },
    ];
}

/**
 * Build list of links for Client column contextual menu on Admin lead log
 * @param {number} contractorId
 * @param {number} hubSpotCompanyId
 * @return {{label: string, url: string}[]}
 */
export function getClientMenuLinks(contractorId: number, hubSpotCompanyId: number) {
    const hubSpotEnvironmentId = process.env.REACT_APP_HUBSPOT_ID

    return [
        {
            label: 'View Contractors View',
            url: `${sdAdminUrl}contractors/?contractor_id=${contractorId}`,
        },
        {
            label: 'View Profiles',
            url: `${sdAdminUrl}profiles/?contractor_ids=${contractorId}`,
        },
        {
            label: 'Edit Contractor',
            url: `${sdAdminUrl}edit_contractor/${contractorId}`,
        },
        {
            label: 'View Industries',
            url: `${sdAdminUrl}industries/?contractor_id=${contractorId}`,
        },
        {
            label: 'View Campaigns',
            url: `${sdAdminUrl}campaigns/?contractor_id=${contractorId}`,
        },
        {
            label: 'View Lead Manager',
            url: `/leads?client_ids[]=${contractorId}`,
        },
        {
            label: 'View Billing Summary',
            url: `${sdAdminUrl}billing_summary/?contractor_id=${contractorId}`,
        },
        {
            label: 'View Current Balance',
            url: `${sdAdminUrl}current_balances?contractor_id=${contractorId}`,
        },
        {
            label: 'View Payment Methods',
            url: `${sdAdminUrl}billing_preferences?contractor_id=${contractorId}`,
        },
        {
            label: 'Open in DB Viewer',
            url: `${sdAdminUrl}db_viewer?sql=select%20*%20from%20contractor%20where%20contractor_id=${contractorId}`,
        },
        {
            label: 'Open in HubSpot',
            url: `https://app.hubspot.com/contacts/${hubSpotEnvironmentId}/company/${hubSpotCompanyId}`,
        },
        {
            key: 'users', // needed for the user data submenu
            label: 'View Users',
            url: `${sdAdminUrl}users?contractor_id=${contractorId}`,
            subMenu: null,
        },
    ]
}

export const getCampaignMenuLinks = ({
    campaignId,
    contractorId,
    profileId,
    siteUrl,
    showViewCampaign = false,
}) => {
    // build list of links for Campaign column contextual menu on Admin lead log
    const links = [
        {
            label: 'Edit Campaign',
            url: `${sdAdminUrl}edit_campaign/${campaignId}`,
        },
        {
            label: 'View Lead Manager',
            url: `/leads?campaign_id=${campaignId}`,
        },
        {
            label: 'View Text Ads',
            url: `${sdAdminUrl}text_ads/?mode=text_ads&campaign_id=${campaignId}&contractor_id=${contractorId}`,
        },
        {
            label: 'Open in DB Viewer',
            url: `${sdAdminUrl}db_viewer?sql=select%20*%20from%20campaign%20where%20campaign_id=${campaignId}`,
        },
    ]

    if (profileId && profileId.length > 0) {
        links.splice(1, 0, {
            label: 'Edit Profile',
            url: `${sdAdminUrl}edit_profile/${profileId}`,
        })
    }

    if (siteUrl && siteUrl.length > 0) {
        links.splice(3, 0, {
            label: 'View Site',
            url: `https://${siteUrl}`,
        })
    }

    if (showViewCampaign) {
        links.splice(1, 0, {
            label: 'View Campaign',
            url: `${sdAdminUrl}campaigns?campaign_id=${campaignId}`,
        })
    }

    return links
}

export function capitalizeFirstLetter(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1)
}

/**
 * builds a URL search segment from the provided pagination and filters
 * @param {IPaginationInterface} [pagination={}] the search pagination
 * @param {Object} [filters={}] the search filters
 * @return {string}
 */
export function buildFilterUrl(pagination: IPaginationInterface = {}, filters = {}): string {
    // add the filters and "order by"
    const filterPairs = []
    Object.keys(filters).forEach((key: string) => {
        filterPairs.push(toSnake(key) + '=' + encodeURIComponent(filters[key]))
    })

    // add pagination
    if (pagination.rowsPerPage) {
        filterPairs.push('pagination[rows_per_page]=' + pagination.rowsPerPage)
    }
    if (pagination.pageNum !== undefined) {
        filterPairs.push('pagination[page_num]=' + pagination.pageNum)
    }

    // if there are filters, return the built string, otherwise return empty string
    if (filterPairs.length > 0) {
        return '?' + filterPairs.join('&')
    } else {
        return ''
    }
}

export function convertToCSV(arr) {
    const array = [Object.keys(arr[0])].concat(arr)

    const result = array
        .map((o) => {
            return Object.values(o)
                .map((v) => {
                    if (v == null) {
                        return 'null'
                    }
                    return v.toString().replaceAll(',', ' ')
                })
                .toString()
        })
        .join('\n')
    return result
}

export function getIntBetween(min, max) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
}

export function isValidBidMultiplier(input) {
    // first validate against regex
    if (input === '.' || input === '') {
        return false
    }
    const regex = /^\d*\.?\d{0,2}$/
    let match = input.match(regex)
    if (!match) {
        return false
    }

    return +input >= 0 && +input <= 0.8
}

/**
 * This function takes an input value and returns true
 * if the input is a valid number. Note that the input
 * can be a string
 * @param {*} str
 * @returns true if the input is a number. false otherwise
 */
export function isNumber(str) {
    return !isNaN(parseFloat(str)) && isFinite(str)
}

export function isValidPhoneNumbersLimit(input) {
    // first validate against regex
    if (input === '.' || input === '') {
        return false
    }
    const regex = /^\d*\.?\d{0,2}$/
    let match = input.match(regex)
    if (!match) {
        return false
    }

    return +input >= 1 && +input <= 1000
}

export function removeWordFromString(inputString, wordToRemove) {
    const regex = new RegExp(`${wordToRemove}`, 'gi')
    return inputString.replace(regex, '').trim()
}

export const compareArrays = (a, b) => {
    return a.length === b.length && a.every((element, index) => element === b[index])
}

/**
 * Takes a phone number in twilio's format (+15555555555) and
 * converts it to (555) 555-5555
 * @param {*} input 
 * @param {*} leadingOne whether or not the incoming number has a leading 1
 * @returns {string} a formatted number or blank
 */
export function formatPhoneNumber(input, leadingOne = true) {
    if (!input) {
        return ''
    }
    // Remove non-digit characters
    var digits = input.replace(/\D/g, '')

    // if there isn't a leading one, add it
    if (!leadingOne) {
        digits = `1${input}`
    }

    // Format the phone number
    var formattedNumber =
        '(' +
        digits.substring(1, 4) +
        ') ' +
        digits.substring(4, 7) +
        '-' +
        digits.substring(7)

    return formattedNumber
}

/**
 * This function takes an array of objects and returns the highest value
 * of a specified property. An optional default value may be specified
 * @param {*} arr 
 * @param {*} propName 
 * @param {*} defaultVal 
 * @returns 
 */
export function findHighestValue(arr, propName, defaultVal = -1) {
    if(!arr) return defaultVal
    return arr.reduce((max, obj) => {
      if (obj[propName] > max) {
        return obj[propName];
      }
      return max;
    }, defaultVal); // Start with a default value
  }
