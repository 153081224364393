import React from 'react';

export default (props) => {
    const timestamp = parseInt(props.timestamp);
    let minutes = `${Math.floor(timestamp / 60)}`;
    let seconds = timestamp % 60;
    seconds = (seconds < 10) ? `0${seconds}` : `${seconds}`;
    const { onClickTimestamp } = props;

    return (
        <tr>
            <td className="timestamp">
                <span
                    role="button"
                    className="type-small-body type-blue type-heavy"
                    onClick={ (e) => {
                        const player = props.audioPlayerRef.current;
                        player.currentTime = timestamp;
                        player.play();
                        props.updateAudioPlaying(true);
                        onClickTimestamp && onClickTimestamp();
                    }}
                >
                    { `${minutes}:${seconds}` }
                </span>
            </td>
            <td className="transcript-text">
                <span className="type-small-body">{ props.transcriptText }</span>
            </td>
        </tr>
    );
}
