import axios from "axios";
import type {CancelTokenSource} from "axios";

import {get, post, put} from '../Requests';
import {buildFilterUrl, toCamelObject, toSnakeObject} from '../Util';
import type {IPaginationInterface} from "../Interfaces/IPaginationInterface";

/**
 * Note that because resources don't change often, we keep a cached copy
 * after one request and return it on consecutive requests
 */
export default class ResourcesService {
    /** @member {CancelTokenSource} Axios cancel token */
    cancelSignal: CancelTokenSource;

    /** @member {object[]} cached version of the time zone resource */
    static timeZones;

    constructor() {
        this.cancelSignal = axios.CancelToken.source();
    }

    /**
     * get all the contractors associated with the requesting user
     * only valid for users of a parent or manager account
     * @return {Promise<object[]>}
     */
    getTimeZones = (): Promise<Object[]> => {
        if (ResourcesService.timeZones) {
            return Promise.resolve(ResourcesService.timeZones);
        }

        return get('resources/time_zones', this.cancelSignal.token)
            .then((response) => {
                ResourcesService.timeZones = [];
                response.data.data.time_zones.forEach(
                    (zone) => ResourcesService.timeZones.push({
                        value: zone.time_zone_id,
                        text: zone.name,
                        group: zone.time_zone_group
                    })
                );

                return ResourcesService.timeZones;
            });
    };

    /**
     * returns the [readable] time zone of the given time zone id
     * @param {number} timeZoneId - the time zone id
     * @return {string}
     */
    timeZoneText = (timeZoneId) => {
        if (!ResourcesService.timeZones) {
            return '';
        }

        let timeZone = ResourcesService.timeZones.find(zone => zone.value == timeZoneId);
        return timeZone
            ? timeZone.text
            : '';
    };

    /**
     * get available ad networks
     * @return {Promise<Object>}
     */
    getAdNetworksForAdmin = (): Promise<Object> => {
        let url = 'ad_networks';

        return get(url, this.cancelSignal.token)
            .then((response) => toCamelObject(response.data.data));
    };

    /**
     * Look up HubSpot company data with a HS company id
     * @return {Promise<Object>}
     */
    getHubSpotCompanyData = (hsCompanyId: number): Promise<Object> => {
        let url = `hubspot/company/${hsCompanyId}`;

        return get(url, this.cancelSignal.token)
            .then((response) => toCamelObject(response.data.data));
    }

    /**
     * get the available industries and the master industries
     * @param {boolean} marketplaceAvailable - if set to true, will return service categories that are available in MP
     * @return {Promise<Object>}
     */
    getIndustries = (marketplaceAvailable: boolean = false): Promise<Object> => {
        let url = 'resources/service_categories';

        if (marketplaceAvailable) {
            url += '?is_marketplace=true';
        }

        return get(url, this.cancelSignal.token)
            .then((response) => toCamelObject(response.data.data));
    };

    /**
     * get list of currently targeted URLs
     * @return {Promise<Object>}
     */
    getTargetedZips = (): Promise<Object> =>
        get('resources/targeted_industries', this.cancelSignal.token)
            .then((response) => toCamelObject(response.data.data));

    /**
     * get the URL to a map displaying a given list of zip codes
     * @param {string[]} zipCodes - list of given zip codes
     * @param {boolean} [isCanada = false] - if set to true, will expect Canadian postal codes
     * @return {Promise<string>}
     */
    getMapByZips = (zipCodes: string[], isCanada: boolean = false): Promise<string> =>
        post('resources/maps/by_zips', toSnakeObject({zipCodes, isCanada}), this.cancelSignal.token)
            .then((response) => response.data.data.map_url);

    /**
     * get available lead statuses to be displayed in Admin Lead Review
     * @return {Promise<Object>}
     */
    getLeadStatusesForAdmin = (): Promise<Object> => {
        let url = 'lead_statuses';

        // Filter just for admin dropdown options
        url += '?status_type_id=2&is_retired=0&show_in_dropdown=1';

        return get(url, this.cancelSignal.token)
            .then((response) => toCamelObject(response.data.data));
    };

    getContractorTypes = (): Promise<Object> =>
        get('contractor_types', this.cancelSignal.token)
            .then((response) => {
                const parsedResponse = toCamelObject(response.data.data);
                // convert type of ELB from "External Buyer" (code) to "External Lead Buyer" (client-facing)
                const externalBuyerContractorType = parsedResponse.contractorTypes
                    .find(type => type.contractorTypeId == 4);
                externalBuyerContractorType.type = 'External Lead Buyer';

                return parsedResponse;
            });

    getEmailAddressesToken: CancelTokenSource;
    /**
     * get all the email addresses in our system
     * @return {Promise<Object>}
     */
    getEmailAddresses = (pagination: IPaginationInterface = {}, filter = {}): Promise<Object> => {
        if (this.getEmailAddressesToken) {
            this.getEmailAddressesToken.cancel();
        }

        let url = 'resources/email_addresses';

        url += buildFilterUrl(pagination, filter);

        this.getEmailAddressesToken = axios.CancelToken.source();
        return get(url, this.getEmailAddressesToken.token)
            .then((response) => toCamelObject(response.data));
    };

    /**
     * resets an email addresses bounce score and relevant fields
     * @param {number|string} emailIdentifier the email address' id (e.g. 123) or actual address ("dev@sd.com")
     * @return {Promise<Object>}
     */
    emailAddressResetBounce = (emailIdentifier: number | string): Promise<Object> =>
        put(`resources/email_addresses/${emailIdentifier}/reset_bounce`, this.cancelSignal.token);
}
