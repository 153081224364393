import queryString from 'query-string';

import TokenService, {AuthData, AccessLevelNames} from "./TokenService";
import {ContractorTypes} from '../common/StaticValues'

const LOCAL_STORAGE_KEY = 'sd-auth-data';

export default class AuthService {
    static data: AuthData = {};

    /**
     * initiates the authentication service by fetching the localStorage data and setting the static data
     */
    static init() {
        try {
            let data: AuthData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {};
            this.updateLoginData(data);
        } catch (ignore) {
        }
    }

    /**
     * updates the auth data with new data while also parsing the data
     *  parses numeric and boolean values
     * @param {AuthData} data - full set of auth data
     */
    static updateLoginData(data: AuthData) {
        const payload = TokenService.getTokenPayload(data.token);

        // user specific values
        data.userId = Number(data.userId || 0);
        data.vicariousAdminId = Number(payload.vicariousAdminId || 0);
        data.isDeveloper = !!payload.isDeveloper;
        data.username = data.username || '';
        data.timeZoneId = Number(data.timeZoneId || 0);
		data.dateCompletedSignup = data.dateCompletedSignup || '';

        // contractor specific values
        data.contractorId = Number(data.contractorId || 0);
        data.parentContractorId = Number(data.parentContractorId || 0);
        data.isParentContractor = data.isParentContractor == 1;
        data.isManagerContractor = data.isManagerContractor == 1;
        data.blockLeadSubmissions = data.blockLeadSubmissions == 1;
        data.completedOnboarding = data.completedOnboarding == 1;
        data.requestedOnboarding = data.requestedOnboarding == 1;
        data.hasQuit = data.hasQuit == 1;

        // general values
        data.authenticated = data.authenticated == 1;
        data.isSignup = data.isSignup == 1;
        data.isAwaitingOnboarding = data.isAwaitingOnboarding == 1;
		data.canceledSsOnboarding = data.canceledSsOnboarding == 1;
        data.signupMarketplaceId = Number(data.signupMarketplaceId || 0);
        data.isAffiliate = data.isAffiliate == 1;
        data.isOnboarding = data.isOnboarding == 1;
        data.onboardingStep1Complete = data.onboardingStep1Complete == 1;
        data.onboardingStep2Complete = data.onboardingStep2Complete == 1;
        data.onboardingStep3Complete = data.onboardingStep3Complete == 1;
        data.onboardingStep4Complete = data.onboardingStep4Complete == 1;
        data.onboardingStep5Complete = data.onboardingStep5Complete == 1;
		data.onboardingStep6Complete = data.onboardingStep6Complete == 1;

        if (this.getUrlCredentials.assistingAdminUserId) {
            data.assistingAdminUserId = this.getUrlCredentials.assistingAdminUserId;
        }

        // update new data only
        this.data = {...this.data, ...data};
        this.updateLocalStorage();
    }

    /**
     * saves the current data image into localStorage
     */
    static updateLocalStorage() {
        try {
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.data));
        } catch (ignore) {
        }
    }

    /**
     * Checks if the contractor for a user can submit leads for review. Defaults to false
     * @return {boolean} returns true if the contractor is able to submit leads for review
     */
    static get blockLeadSubmissions(): boolean {
        return this.data.blockLeadSubmissions == 1;
    }

    /**
     * checks if the logged in user is of a "Marketplace" contractor
     * @return {boolean} returns true if the user is of a "Marketplace" contractor
     */
    static get isMarketplace(): boolean {
        return this.data.contractorType === ContractorTypes.Marketplace;
    }

    /**
     * checks if the user is authenticated
     * @return {boolean} - returns true if the user is authenticated
     */
    static get isAuthenticated(): boolean {
        return this.data.authenticated;
    }

    /**
     * checks if the user is of a parent or manager contractor
     * @return {boolean} - returns true if the user is a parent or manager contractor
     */
    static get isParentOrManager(): boolean {
        return this.data.isParentContractor || this.data.isManagerContractor;
    }

    /**
     * special check for the Nexstar parent contractor
     * @return {boolean} - returns true if the user is of the Nexstar contractor
     */
    static get isNexstar(): boolean {
        return this.data.contractorId === 117;
    }

    /**
     * returns the value of a given data key
     * @param {string} key - the data key
     * @return {*} the value of the key; returns "undefined" for invalid keys
     */
    static getItem(key: string): any {
        return this.data[key];
    }

    /**
     * updates the value of a data key
     * @param {string} key - the data key
     * @param {any} value - the new value of the key
     */
    static setItem(key: string, value: any) {
        this.data[key] = value;
        this.updateLocalStorage();
    }

    /**
     * checks whether the user is in the signup process
     * @return {boolean} - returns true if the user is in the signup process
     */
    static get isSignup(): boolean {
        return this.data.isSignup;
    }

    /**
     * sets the current user signup state
     * @param isSignup
     */
    static set isSignup(isSignup: boolean) {
        this.data.isSignup = isSignup;
        this.updateLocalStorage();
    }
    
    /**
     * checks whether the user is waiting to be onboarded
     * @return {boolean} - returns true if the user has not completed onboarding
     */
    static get isAwaitingOnboarding(): boolean {
        return this.data.isAwaitingOnboarding;
    }
    
    /**
     * sets the current user AwaitingOnboarding
     * @param isAwaitingOnboarding 
     */
    static set isAwaitingOnboarding(isAwaitingOnboarding: boolean) {
        this.data.isAwaitingOnboarding = isAwaitingOnboarding;
        this.updateLocalStorage();
    }
    
	static get canceledSsOnboarding(): boolean {
		return this.data.canceledSsOnboarding;
	}
	
	static set canceledSsOnboarding(canceledSsOnboarding: boolean) {
		this.data.canceledSsOnboarding = canceledSsOnboarding;
		this.updateLocalStorage();
	}
	
    static get requestedOnboarding(): boolean {
        return this.data.requestedOnboarding;
    }
    
    static set requestedOnboarding(requestedOnboarding: boolean) {
        this.data.requestedOnboarding = requestedOnboarding;
        this.updateLocalStorage();
    }

    /**
     * checks whether the user should be shown Affiliate Insights
     * @return {boolean} - returns true if the user is from Affiliate or Affiliate and External Buyer contractor
     */
    static get isAffiliate(): boolean {
        return this.data.isAffiliate;
    }

    /**
     * sets the current users affiliate status
     * @param isAffiliate
     */
    static set isAffiliate(isAffiliate: boolean) {
        this.data.isAffiliate = isAffiliate;
        this.updateLocalStorage();
    }

    /**
     * checks whether the user is in the self-serve onboarding process
     * @return {boolean} - returns true if the user is in the self-serve onboarding
     */
    static get isOnboarding(): boolean {
        return this.data.isOnboarding;
    }
    
    /**
     * sets the current user onboarding state
     * @param isOnboarding
     */
    static set isOnboarding(isOnboarding: boolean) {
        this.data.isOnboarding = isOnboarding;
        this.updateLocalStorage();
    }
    
    static get onboardingStep(): number {
		if (this.data.onboardingStep6Complete) {
			return 7;
		} else if (this.data.onboardingStep5Complete) {
            return 6;
        } else if (this.data.onboardingStep4Complete) {
            return 5;
        } else if (this.data.onboardingStep3Complete) {
            return 4;
        } else if (this.data.onboardingStep2Complete) {
            return 3;
        } else if (this.data.onboardingStep1Complete) {
            return 2;
        }
        
        if (this.data.isOnboarding) {
            return 1;
        }
        
        return 0;
    }

    /**
     * returns the current OAuth token
     * @return {string}
     */
    static get token(): string {
        return this.data.token;
    }

    /**
     * sets the current OAuth token
     * @param {string} token
     */
    static set token(token: string) {
        this.data.token = token;
        this.updateLocalStorage();
    }

    /**
     * @return {Number}
     */
    static get assistingAdminUserId(): number {
        return Number(this.data.assistingAdminUserId) || 0;
    }

    /**
     * @param {Number} userId
     */
    static set assistingAdminUserId(userId: number) {
        this.data.assistingAdminUserId = Number(userId);
        this.updateLocalStorage();
    }

    /**
     * get the vicariousAdminId if any
     * @return {number}
     */
    static get vicariousAdminId(): number {
        return this.data.vicariousAdminId || 0;
    }
    
    /**
     * get userId
     * @return {number}
     */
    static get userId(): number {
        return this.data.userId;
    }

    /**
     * checks if the logged-in user is a developer
     * @returns {bool} returns true iff the logged-in user is a developer
     */
    static get isDeveloper(): boolean {
        return this.data.isDeveloper;
    }
    
    /**
     * get contractorId
     * @return {number}
     */
    static get contractorId(): number {
        return this.data.contractorId;
    }
    

    /**
     * checks whether the user can unpause campaigns
     * true iff is not a marketplace contractor or completed the onboarding process
     * @return {boolean}
     */
    static get canUnpauseCampaign(): boolean {
		if (!this.data.completedOnboarding && this.data.dateCompletedSignup < '2023-12-06 09:26:00') {
			return true;
		}
        return !this.isMarketplace || this.data.completedOnboarding;
    }

    /**
     * checks if the logged in user is an admin
     * @return {boolean} returns true iff the user is an admin
     */
    static get isAdmin(): boolean {
        return [
            AccessLevelNames.SuperUser,
            AccessLevelNames.Admin,
            AccessLevelNames.LeadReviewer
        ].includes(this.getItem('accessLevelName'));
    }

    /**
     * Returns true if Client has completed Onboarding
     * @return {boolean} completedOnboarding
     */
    static get completedOnboarding():boolean {
        return this.data.completedOnboarding
        }

    /**
     * Returns true if Client has Quit
     * @return {boolean} hasQuit
     */
    static get hasQuit() {
        return this.data.hasQuit
        }

    /**
     * clears the data from the service and from localStorage
     */
    static clear() {
        this.data = {};
        try {
            localStorage.clear();
        } catch (ignore) {
        }
    }

    /**
     * @return {{username: string, password: string, vicariousAdminId: number, assistingAdminUserId: number}}
     */
    static getUrlCredentials(): Object {
        const params = queryString.parse(window.location.search);
        const credentials = {
            username: params.username,
            password: params.pass_word, // URL password from admin login comes as pass_word
            vicariousAdminId: Number(params.vicarious_admin_id || 0),
            assistingAdminUserId: Number(params.assisting_admin_user_id || 0)
        };

        //
        // replacing whitespaces with "+" because queryString internal process does that,
        // and we still want to keep the the rest of the decoding options
        //
        if (credentials.username) {
            credentials.username = credentials.username.replace(' ', '+');
        }

        return credentials;
    }
}
