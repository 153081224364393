import React, {useState} from "react";

import RadioGroup from "../../../common/components/form_elements/RadioGroup";
import PopupForm from "../../../common/popup_form/PopupForm";
import PopupFormGroup from "../../../common/popup_form/PopupFormGroup";
import PopupFormRow from "../../../common/popup_form/PopupFormRow";
import PopupFormCell from "../../../common/popup_form/PopupFormCell";
import PopupFormDivider from "../../../common/popup_form/PopupFormDivider";
import ClickableDisabledButton from "../../../common/ClickableDisabledButton";
import Validator from "../../../lib/Validator";
import type {User} from "../../../Interfaces/User";

import NewAffiliateUserPane from "./NewAffiliateUserPane";
import NewClientUserPane from "./NewClientUserPane";
import NewAdminUserPane from "./NewAdminUserPane";

const AddNewUserModal = ({
    addUser,
    updateModalContent,
}) => {
    const [selectedUserType, setSelectedUserType] = useState(null);
    const [adminUserState, setAdminUserState] = useState({
        accessLevel: undefined,
        email: '',
        timeZone: undefined,
    });
    const [clientUserState, setClientUserState] = useState({
        company: undefined,
        isParentOrManager: false,
        email: '',
        billingNotifications: false,
        subAccountBillingNotifications: false,
        leadReviewNotifications: false,
        subAccountLeadReviewNotifications: false,
        accountNotifications: false,
        name: '',
        title: '',
        phone: '',
        timeZone: undefined,
    });
    const [affiliateUserState, setAffiliateUserState] = useState({
        company: undefined,
        email: '',
        name: '',
        title: '',
        phone: '',
        timeZone: undefined,
    });
    const emptyErrorMessageState = {
        userType: '',
        adminAccessLevel: '',
        adminEmail: '',
        adminTimeZone: '',
        clientCompany: '',
        clientEmail: '',
        clientName: '',
        clientTimeZone: '',
        affiliateCompany: '',
        affiliateEmail: '',
        affiliateName: '',
        affiliateTimeZone: '',
    };
    const [errorMessageState, setErrorMessageState] = useState(emptyErrorMessageState);
    const [submitting, setSubmitting] = useState(false);

    const handleSubmissionClick = () => {
        if (!validateFormContents()) {
            return;
        }

        setSubmitting(true);

        let userProps: Partial<User> = {};
        switch (selectedUserType) {
            case "admin":
                userProps = {
                    userType: "admin",
                    contactEmailAddress: adminUserState.email,
                    timeZoneId: adminUserState.timeZone,
                };
                break;
            case "client":
                userProps = {
                    userType: "client",
                    contractorId: clientUserState.company,
                    contactEmailAddress: clientUserState.email,
                    name: clientUserState.name.trim(),
                    timeZoneId: clientUserState.timeZone,
                    jobTitle: clientUserState.title,
                    phoneNumber: clientUserState.phone,
                    billingNotifications: Number(clientUserState.billingNotifications),
                    leadReviewNotifications: Number(clientUserState.leadReviewNotifications),
                    accountNotifications: Number(clientUserState.accountNotifications),
                };

                if (clientUserState.isParentOrManager) {
                    userProps.subaccountBillingNotifications = Number(clientUserState.subAccountBillingNotifications);
                    userProps.subaccountLeadReviewNotifications = Number(clientUserState.subAccountLeadReviewNotifications);
                }
                break;
            case "affiliate":
                userProps = {
                    userType: "client",
                    contractorId: affiliateUserState.company,
                    contactEmailAddress: affiliateUserState.email,
                    name: affiliateUserState.name.trim(),
                    timeZoneId: affiliateUserState.timeZone,
                    jobTitle: affiliateUserState.title,
                    phoneNumber: affiliateUserState.phone,
                };
                break;
        }

        addUser(userProps)
            .then(() => setSubmitting(false));
    }

    const isSubmissionDisabled = () => {
        if (submitting) {
            return true;
        }

        switch (selectedUserType) {
            case "admin":
                return !(adminUserState.accessLevel &&
                         adminUserState.email.length > 0 &&
                         adminUserState.timeZone);
            case "client":
                return !(clientUserState.company &&
                         clientUserState.email.length > 0 &&
                         clientUserState.name.length > 0 &&
                         clientUserState.timeZone);
            case "affiliate":
                return !(affiliateUserState.company &&
                         affiliateUserState.email.length > 0 &&
                         affiliateUserState.name.length > 0 &&
                         affiliateUserState.timeZone);
            default:
                return true;
        }
    };

    const validateFormContents = () => {
        let newErrorMessageState = {...emptyErrorMessageState};

        switch (selectedUserType) {
            case "admin":
                if (!adminUserState.accessLevel) {
                    newErrorMessageState.adminAccessLevel = "Please select an Access Level";
                }
                if (!Validator.validateEmail(adminUserState.email)) {
                    newErrorMessageState.adminEmail = "Please fill in a valid Email Address";
                }
                if (!adminUserState.timeZone) {
                    newErrorMessageState.adminTimeZone = "Please select a Time Zone";
                }
                break;
            case "client":
                if (!clientUserState.company) {
                    newErrorMessageState.clientCompany = "Please select a Company";
                }
                if (!Validator.validateEmail(clientUserState.email)) {
                    newErrorMessageState.clientEmail = "Please fill in a valid Email Address"
                }
                if (clientUserState.name.length === 0) {
                    newErrorMessageState.clientName = "Please fill in a Name";
                }
                if (!clientUserState.timeZone) {
                    newErrorMessageState.clientTimeZone = "Please select a Time Zone";
                }
                break;
            case "affiliate":
                if (!affiliateUserState.company) {
                    newErrorMessageState.affiliateCompany = "Please select an Affiliate Company";
                }
                if (!Validator.validateEmail(affiliateUserState.email)) {
                    newErrorMessageState.affiliateEmail = "Please fill in a valid Email Address";
                }
                if (affiliateUserState.name.length === 0) {
                    newErrorMessageState.affiliateName = "Please fill in a Name";
                }
                if (!affiliateUserState.timeZone) {
                    newErrorMessageState.affiliateTimeZone = "Please select a Time Zone";
                }
                break;
            default:
                newErrorMessageState.userType = "Please select a User Type";
                break;
        }

        setErrorMessageState(newErrorMessageState);

        return Object.values(newErrorMessageState).every(value => value.length === 0);
    };

    const paneContent = () => {
        switch (selectedUserType) {
            case "admin":
                return (
                    <NewAdminUserPane
                        adminUserState={adminUserState}
                        setAdminUserState={setAdminUserState}
                        errorMessageState={errorMessageState}
                    />
                );
            case "client":
                return (
                    <NewClientUserPane
                        clientUserState={clientUserState}
                        setClientUserState={setClientUserState}
                        errorMessageState={errorMessageState}
                    />
                );
            case "affiliate":
                return (
                    <NewAffiliateUserPane
                        affiliateUserState={affiliateUserState}
                        setAffiliateUserState={setAffiliateUserState}
                        errorMessageState={errorMessageState}
                    />
                );
            default:
                // Return empty placeholder div if nothing selected
                return <div style={{minHeight: "300px"}}/>;
        }
    };

    return (
        <>
        <PopupForm>
                <PopupFormGroup>
                    <PopupFormRow>
                        <PopupFormCell>
                            <RadioGroup
                                name="user_type"
                                label="User Type *"
                                options={[
                                    {label: "SD Admin User", value: "admin"},
                                    {label: "mySD Client User", value: "client"},
                                    {label: "Affiliate Insights User", value: "affiliate"},
                                ]}
                                onChange={(name, val) => setSelectedUserType(val)}
                                checkedOption={selectedUserType}
                                isClearable={false}
                                align="spread"
                                hasError={errorMessageState.userType.length > 0}
                                errorMessage={errorMessageState.userType}
                            />
                        </PopupFormCell>
                    </PopupFormRow>
                </PopupFormGroup>

                <PopupFormDivider/>

                {paneContent()}

                <div className="ui-hide-full ui-hide-tablet">
                    <div className="popup__form__row spacing-40-top-mobile">
                        <div className="popup__form__cell popup__form__cell__100__mobile">
                            <ClickableDisabledButton
                                disabled={isSubmissionDisabled()}
                                onClick={handleSubmissionClick}
                                disabledClickHandler={validateFormContents}
                            >
                                Add New User
                            </ClickableDisabledButton>
                        </div>
                    </div>
                </div>
                <div className="ui-hide-mobile">
                    <div className="popup__form__gray-bar padding-24 spacing-30-top no-margin-bottom">
                        <div className="popup__form__row popup__form__row__slam-right">
                            <div className="popup__form__cell">
                                <span
                                    role="button"
                                    className="type-small-body type-heavy type-blue"
                                    onClick={() =>
                                        updateModalContent(
                                            undefined,
                                            undefined
                                        )
                                    }
                                >
                                    Cancel
                                </span>
                            </div>
                            <div className="popup__form__cell">
                                <ClickableDisabledButton
                                    disabled={isSubmissionDisabled()}
                                    onClick={handleSubmissionClick}
                                    disabledClickHandler={validateFormContents}
                                >
                                    Add New User
                                </ClickableDisabledButton>
                            </div>
                        </div>
                    </div>
                </div>
            </PopupForm>
        </>
    );
};

export default AddNewUserModal;