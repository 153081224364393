import React, {useState, useEffect} from "react";

import ContractorService from "../../../service/ContractorService";
import MultiSelectList from "../../../common/components/form_elements/MultiSelectList";
import PopupFormGroup from "../../../common/popup_form/PopupFormGroup";
import PopupFormRow from "../../../common/popup_form/PopupFormRow";
import PopupFormCell from "../../../common/popup_form/PopupFormCell";
import PasswordStub from "./PasswordStub";
import Input from "../../../common/components/form_elements/Input";
import TimeZoneSelector from "../../../common/components/TimeZoneSelector";
import Checkbox from "../../../common/components/form_elements/Checkbox";
import {errorHandler} from "../../../Requests";

const NewClientUserPane = ({
    clientUserState,
    setClientUserState,
    errorMessageState,
}) => {
    const [contractorOptions, setContractorOptions] = useState([]);
    const [contractors, setContractors] = useState([]);

    const isParentOrManager = (contractorId) => {
        return contractors.reduce((acc, val) => {
            if (val["contractor_id"] === contractorId) {
                if (val["is_parent_contractor"] == 1 || val["is_manager_contractor"] == 1) {
                    return true;
                }
            }

            return acc;
        }, false);
    };

    useEffect(() => {
        const contractorService = new ContractorService();

        contractorService.getActiveContractors()
            .then((response: Object) => {
                setContractors(response);
                setContractorOptions(response.map(contractor => { return { text: contractor.name, value: contractor.contractor_id }}));
            })
            .catch(errorHandler);
    }, []);

    return (
        <PopupFormGroup>
            <PopupFormRow
                fullWidthOnMobile={true}
            >
                <PopupFormCell>
                    <MultiSelectList
                        name="client-company"
                        label="Client Company Name"
                        required={true}
                        options={contractorOptions}
                        selections={clientUserState.company ? [clientUserState.company] : null}
                        emptyLabel="Please Select"
                        onChange={(val) => {
                            const _stateClone = {...clientUserState};
                            _stateClone.company = val[0];
                            _stateClone.isParentOrManager = isParentOrManager(val[0]);
                            setClientUserState(_stateClone);
                        }}
                        hasError={errorMessageState.clientCompany.length > 0}
                        errorMessage={errorMessageState.clientCompany}
                        useTextFilter={true}
                    />
                </PopupFormCell>
            </PopupFormRow>
            <PopupFormRow
                fullWidthOnMobile={true}
            >
                <PopupFormCell>
                    <Input
                        type="text"
                        name="client-email"
                        label="Email *"
                        subLabel="(will also be their Username)"
                        val={clientUserState.email}
                        onChange={(e) => {
                            const _stateClone = {...clientUserState};
                            _stateClone.email = e.target.value;
                            setClientUserState(_stateClone);
                        }}
                        hasError={errorMessageState.clientEmail.length > 0}
                        errorMessage={errorMessageState.clientEmail}
                    />
                </PopupFormCell>
                <PopupFormCell>
                    <PasswordStub />
                </PopupFormCell>
            </PopupFormRow>
            <PopupFormRow
                fullWidthOnMobile={true}
            >
                <PopupFormCell>
                    <span className="type-small-body type-narrow-line-height type-heavy spacing-5-bottom">
                        Receives Notifications for
                    </span>
                    <div className="simpleflex__row simpleflex__row__wrap__mobile spacing-20-bottom">
                        <div className="simpleflex__cell simpleflex__cell__full-width__mobile spacing-20-bottom-mobile">
                            <Checkbox name="billing_notifications"
                                  label="Billing"
                                  checked={clientUserState.billingNotifications}
                                  onChange={() => {
                                      const _stateClone = {...clientUserState};
                                      _stateClone.billingNotifications = !clientUserState.billingNotifications;
                                      setClientUserState(_stateClone);
                                  }}
                            />
                            {clientUserState.isParentOrManager &&
                                <Checkbox name="subaccount_billing_notifications"
                                    label="Sub-Account Billing"
                                    customClasses="spacing-10-top"
                                    checked={clientUserState.subAccountBillingNotifications}
                                    onChange={() => {
                                        const _stateClone = {...clientUserState};
                                        _stateClone.subAccountBillingNotifications = !clientUserState.subAccountBillingNotifications;
                                        setClientUserState(_stateClone);
                                    }}
                                />
                            }
                        </div>
                        <div className="simpleflex__cell simpleflex__cell__full-width__mobile spacing-20-bottom-mobile">
                            <Checkbox name="lead_review_notifications"
                                  label="Lead Review"
                                  checked={clientUserState.leadReviewNotifications}
                                  onChange={() => {
                                      const _stateClone = {...clientUserState};
                                      _stateClone.leadReviewNotifications = !clientUserState.leadReviewNotifications;
                                      setClientUserState(_stateClone);
                                  }}
                            />
                            {clientUserState.isParentOrManager &&
                                <Checkbox name="subaccount_lead_review_notifications"
                                    label="Sub-Account Lead Review"
                                    customClasses="spacing-10-top"
                                    checked={clientUserState.subAccountLeadReviewNotifications}
                                    onChange={() => {
                                        const _stateClone = {...clientUserState};
                                        _stateClone.subAccountLeadReviewNotifications = !clientUserState.subAccountLeadReviewNotifications;
                                        setClientUserState(_stateClone);
                                    }}
                                />
                            }
                        </div>
                        <div className="simpleflex__cell simpleflex__cell__full-width__mobile">
                            <Checkbox name="account_notifications"
                                  label="Account"
                                  checked={clientUserState.accountNotifications}
                                  onChange={() => {
                                      const _stateClone = {...clientUserState};
                                      _stateClone.accountNotifications = !clientUserState.accountNotifications;
                                      setClientUserState(_stateClone);
                                  }}
                            />
                        </div>
                    </div>
                </PopupFormCell>
            </PopupFormRow>
            <PopupFormRow
                fullWidthOnMobile={true}
            >
            <PopupFormCell>
                    <Input
                       type="text"
                       name="client-name"
                       label="Name *"
                       required={true}
                       value={clientUserState.name}
                       onChange={(e) => {
                           const _stateClone = {...clientUserState};
                           _stateClone.name = e.target.value;
                           setClientUserState(_stateClone);
                       }}
                       hasError={errorMessageState.clientName.length > 0}
                       errorMessage={errorMessageState.clientName}
                   />
                </PopupFormCell>
                <PopupFormCell>
                    <Input
                       type="text"
                       name="client-title"
                       label="Title / Role"
                       value={clientUserState.title}
                       onChange={(e) => {
                           const _stateClone = {...clientUserState};
                           _stateClone.title = e.target.value;
                           setClientUserState(_stateClone);
                       }}
                   />
                </PopupFormCell>
            </PopupFormRow>
            <PopupFormRow
                fullWidthOnMobile={true}
            >
                <PopupFormCell>
                    <Input
                        type="text"
                        name="client-phone"
                        label="Phone"
                        value={clientUserState.phone}
                        onChange={(e) => {
                            const _stateClone = {...clientUserState};
                            _stateClone.phone = e.target.value;
                            setClientUserState(_stateClone);
                        }}
                   />
                </PopupFormCell>
                <PopupFormCell>
                    <TimeZoneSelector
                        name="client-time-zone"
                        selectedOption={clientUserState.timeZone}
                        onChange={(val) => {
                            const _stateClone = {...clientUserState};
                            _stateClone.timeZone = val[0];
                            setClientUserState(_stateClone);
                        }}
                        hasError={errorMessageState.clientTimeZone.length > 0}
                        errorMessage={errorMessageState.clientTimeZone}
                    />
                </PopupFormCell>
            </PopupFormRow>
        </PopupFormGroup>
    )
};

export default NewClientUserPane;