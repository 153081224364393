import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {formatDate, getClientMenuLinks, getUserMenuLinks} from "../../Util";
import {setUsersSubMenu} from "../../UtilJsx";
import type {User} from "../../Interfaces/User";
import {CheckMarkSvg, CloseSvg} from "../../common/Svgs";
import AuthService from "../../service/AuthService";
import UserService from "../../service/UserService";
import ContextualMenu from "../../common/ContextualMenu";
import DeleteUserModal from "../../client/settings/DeleteUserModal";
import Tooltip from "../../common/Tooltip";

const hubSpotEnvironmentId = process.env.REACT_APP_HUBSPOT_ID;

export default class UserRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userMenuLinks: [{
                label: 'Loading...',
                url: ``,
                disabled: true
            },],
            clientMenuLinks: [],
            displayBounce: false
        };

        this.userService = new UserService();
    }

    componentDidMount(): void {
        const {user}: User = this.props;
        const clientMenuLinks = getClientMenuLinks(user.contractorId, user.hubspotCompanyId);
        this.setState({clientMenuLinks});
    }

    componentWillUnmount(): void {
        this.userService.cancelSignal.cancel();
    }

    getUsersLinks = (): void => {
        const {user}: User = this.props;
        let {clientMenuLinks, userMenuLinks} = this.state;
        this.userService.getByAdmin(this.props.user.contractorId)
            .then((users) => {
                users.forEach((u) => {
                    if (u.username === user.username) {
                        userMenuLinks = getUserMenuLinks(u.id, u.username, u.password, AuthService.getItem('userId'));
                    }
                })
                setUsersSubMenu(users, clientMenuLinks);
                this.setState({clientMenuLinks, userMenuLinks});
            });
    };

    toggleBounceInfo = (): void => {
        this.setState({displayBounce: !this.state.displayBounce});
    };

    /**
     * opens the delete user modal
     */
    deleteUser = () => {
        const user: User = this.props.user;

        this.props.updateModalContent(
            <DeleteUserModal
                user={user}
                updateModalContent={this.props.updateModalContent}
                updateHubSpot={true}
                deleteUser={
                    (userId, updateHubSpot) => {
                        this.userService.delete(userId, updateHubSpot)
                            .then((response) => {
                                // notify the parent of the user "deletion"
                                this.props.onDeleteUser(userId);
                                // notify the user of the retire success
                                this.props.updateMessageBlocks(response.data.message, 'success');
                            })
                            .then((response) => this.props.updateModalContent(response))
                    }
                }
            />,
            {header: 'Delete User'}
        );
    };

    render() {
        const user: User = this.props.user;

        return <tr className="sortable-table__row">
            <td>
                <button className="button-clean ui-float-right" title="Delete user"
                        onClick={this.deleteUser}>
                    <span className="inline-icon inline-icon__middle inline-icon__20">{CloseSvg}</span>
                </button>
                <ContextualMenu
                    linkLabel={user.username}
                    menuLinks={this.state.userMenuLinks}
                    onFirstOpen={this.getUsersLinks}
                    scrollboxRef={this.props.scrollboxRef}
                />
                {user.username != user.userEmailAddress &&
                    <p className="text-alert type-heavy">User email: {user.userEmailAddress}</p>}
                {user.contactEmailAddress != user.userEmailAddress &&
                    <p className="text-alert type-heavy">Contact email: {user.contactEmailAddress}</p>}
                <p className="type-force-newline type-extra-small-body type-single-line">{user.name}</p>
            </td>
            <td>
                <div className="simpleflex__row">
                    <div className="simpleflex__cell">
                        <ContextualMenu
                            linkLabel={user.contractorName}
                            menuLinks={this.state.clientMenuLinks}
                            scrollboxRef={this.props.scrollboxRef}
                            onFirstOpen={this.getUsersLinks}
                        />
                    </div>
                </div>
            </td>
            <td>
                {user.contractorType}
            </td>
            <td>
                {user.accessLevel}
            </td>
            <td>{user.phoneNumber}</td>
            <td>
                {user.hubspotContactVid
                    ?
                    <a href={`https://app.hubspot.com/contacts/${hubSpotEnvironmentId}/contact/${user.hubspotContactVid}`}
                       target="_blank">{user.hubspotContactVid}</a>
                    : <span className="type-grey">&lt;No HS Contact&gt;</span>}
            </td>
            <td>
                <Tooltip
                    anchor={<span className="type-normal-body type-black">{formatDate(user.creationTimestamp)}</span>}
                    content={user.creationTimestamp}
                    position="bottom"
                />
            </td>
            <td>
                {user.lastModified ? (
                    <Tooltip
                        anchor={<span className="type-normal-body type-black">{formatDate(user.lastModified)}</span>}
                        content={user.lastModified}
                        position="bottom"
                    />
                ) : "Never"}
            </td>
            <td>
                {user.lastLogin ? (
                    <Tooltip
                        anchor={<span className="type-normal-body type-black">{formatDate(user.lastLogin)}</span>}
                        content={user.lastLogin}
                        position="bottom"
                    />
                ) : "Never"}
            </td>
            <td>
                <Tooltip
                    anchor={<span className="type-normal-body type-black">{formatDate(user.lastSent)}</span>}
                    content={user.lastSent}
                    position="bottom"
                />
            </td>
            <td>
                {user.bounce > 0 &&
                    <Tooltip
                        anchor={<span className="type-normal-body type-black">{user.bounce}</span>}
                        content={(
                            <>
                                Bounce Score: {user.bounce}
                                <p className="spacing-12">
                                    Send Email Disabled on {formatDate(user.latestBounceTimestamp)}
                                    {user.bounceExpire &&
                                        <span>
                                        , expires {formatDate(user.bounceExpire)}
                                    </span>}
                                </p>
                            </>
                        )}
                        position="bottom"
                    />
                }
                {user.subType &&
                    (this.state.displayBounce
                        ? <>
                            <div className="type-keep-newline spacing-12-bottom">{user.subType}</div>
                            <button className="type-force-newline button-clean type-blue"
                                    onClick={this.toggleBounceInfo}>
                                Hide
                            </button>
                        </>
                        : <button onClick={this.toggleBounceInfo}
                                  className="type-force-newline button-clean type-blue">More...</button>)}
            </td>
            <td className="spam-column type-centered">{user.markedSpam == 1 && CheckMarkSvg}</td>
            <td>
                <Tooltip
                    anchor={(
                        <>
                            <span style={{whiteSpace: "nowrap"}} className={user.deliveries == 0 ? 'type-grey' : ''}> D: {user.deliveries}</span>{' '}
                            <span style={{whiteSpace: "nowrap"}} className={user.bounces == 0 ? 'type-grey' : ''}>B: {user.bounces}</span>{' '}
                            <span style={{whiteSpace: "nowrap"}} className={user.complaints == 0 ? 'type-grey' : ''}>C: {user.complaints}</span>
                        </>
                    )}
                    content={(
                        <>
                            <div className={user.deliveries == 0 ? 'type-grey' : ''}>Deliveries: {user.deliveries}</div>
                            <div className={user.bounces == 0 ? 'type-grey' : ''}>Bounces: {user.bounces}</div>
                            <div className={user.complaints == 0 ? 'type-grey' : ''}>Complaints: {user.complaints}</div>
                        </>
                    )}
                    position="top"
                />
            </td>
        </tr>;
}
}

UserRow.propTypes = {
    user: PropTypes.object.isRequired,
    scrollboxRef: PropTypes.object,
    updateModalContent: PropTypes.func,
    onDeleteUser: PropTypes.func,
    updateMessageBlocks: PropTypes.func
};
