import React from 'react';

import CallOutBox from './CallOutBox';
import CoreSection from './CoreSection';
import ExpandableSection from './ExpandableSection';
import ImageBox from './ImageBox';
import QuizSection from './QuizSection';
import Sidebar from './sidebar';
import WistiaPlayer from '../common/WistiaPlayer';

const StepThree = (props) => {
	return (
		<div className="onboarding-contents page-width">
			<Sidebar step={3} {...props} />
			
			<div className="onboarding-main-column">
				<CoreSection
					headerLabel="Section 3"
					header="Billable vs. Non-Billable Leads"
				>
					<h4 className="type-large-subhead type-heavy spacing-40-bottom">
						Let’s Review What Makes a Lead Billable
					</h4>
					
					<div className="spacing-40-bottom">
						<WistiaPlayer
							videoId="czpe3yxdse"
							wrapper="wistia-player-1"
						/>
					</div>
					
					<h5 className="type-normal-subhead type-heavy spacing-40-top spacing-24-bottom">
						Key Concepts
					</h5>
					
					<p className="type-normal-subhead type-narrow-line-height spacing-40-bottom">
						Our Platform combines automated smart technologies with our human Quality Assurance Team to proactively identify and remove Non-Billable Leads like Spam, Job Seekers, and more. But, it’s also the responsibility of our Clients to manage their Leads and Submit them for our team to review.
					</p>
					
					<div className="onboarding__columns-block">
						<div className="column">
							<p className="type-large-body type-heavy">
								Billable Lead
							</p>
							<ul className="type-large-body type-narrow-line-height">
								<li>
									Any Lead that occurs when a New Potential Customer is seeking a service that your Service Category typically provides.
								</li>
							</ul>
						</div>
						<div className="column">
							<p className="type-large-body type-heavy">
								Non-Billable Lead
							</p>
							<ul className="type-large-body type-narrow-line-height">
								<li>
									Answered Phone Call Leads that had no service need from your company or Form Leads with non-working customer contact information.
								</li>
							</ul>
						</div>
					</div>
				</CoreSection>
				
				<ExpandableSection
					header="Billable and Non-Billable Lead Examples"
				>
					<h4 className="type-large-subhead type-heavy spacing-24-bottom">
						Not Every Lead is a Billable Lead
					</h4>
					<p className="type-normal-subhead type-narrow-line-height">
						Most of the calls that we will send you will be from New Potential Customers looking for service. However, not every call that you receive will be considered a Billable Lead. At the same time, it is unlikely that every Billable Lead will turn into a Booked Appointment.
					</p>
					<div className="onboarding__columns-block spacing-40">
						<div className="column">
							<CallOutBox
								headline="Billable Lead Examples"
							>
								<ul className="type-large-body type-narrow-line-height">
									<li>
										Caller seeks a service that your Service Category typically provides.
									</li>
									<li>
										Caller doesn’t turn into a Booked Appointment (price shopper, books service with a competitors, cancels appointment).
									</li>
									<li>
										Missed or Dropped Calls are considered Billable since we cannot investigate them when there’s no Service Direct Call Recording to listen to.
									</li>
									<li>
										All Leads resulting in Booked Appointments are considered Billable.
									</li>
								</ul>
							</CallOutBox>
						</div>
						<div className="column">
							<CallOutBox
								headline="Non-Billable Lead Examples"
							>
								<ul className="type-large-body type-narrow-line-height">
									<li>
										Call is less than 20 seconds in duration
									</li>
									<li>
										Caller is Spam, a Robocall, or is clearly identifiable as a Wrong Number
									</li>
									<li>
										Caller is seeking employment or is soliciting
									</li>
									<li>
										Caller is seeking a Service Category you do not provide (e.g. You are a plumber, but this caller requires an electrician)
									</li>
									<li>
										Caller is a previous customer
									</li>
									<li>
										Caller is located outside of your Service Area
									</li>
									<li>
										Form Lead has non-working customer contact information
									</li>
									<li>
										Call or Form Lead came while your Campaign was Paused
									</li>
								</ul>
							</CallOutBox>
						</div>
					</div>
					
					<CallOutBox
						headline="Learn More"
					>
						<ul className="type-large-body type-narrow-line-height">
							<li>
								Read our Help Center article <a href="https://support.servicedirect.com/billable-and-non-billable-leads" target="_blank" className="type-heavy">Understanding Billable and Non-Billable Leads</a>
							</li>
						</ul>
					</CallOutBox>
				</ExpandableSection>
				
				<ExpandableSection
					header="The Lead Review Process"
				>
					<h4 className="type-large-subhead type-heavy spacing-24-bottom">
						Special Lead Review for the First 30 Days
					</h4>
					<ul className="type-large-body type-narrow-line-height">
						<li>
							As a new Service Direct Client, we will do a special review of up to the first 200 Leads during the first 30 days of your Account with us.
						</li>
						<li>
							We will also include our Call Insights service at no charge to provide valuable info about your Leads.
						</li>
						<li>
							After the first 30 days, we will continue our standard automated and human Lead Review process but you will be responsible for submitting Leads for Review unless you choose to continue the Call Insights service.
						</li>
					</ul>
					
					<CallOutBox
						headline="Learn More"
						extraClasses="spacing-40"
					>
						<ul className="type-large-body type-narrow-line-height">
							<li>
								Read our Help Center article <a href="https://support.servicedirect.com/call-insights-service" target="_blank" className="type-heavy">Call Insights Service</a>
							</li>
						</ul>
					</CallOutBox>
					
					<h4 className="type-large-subhead type-heavy spacing-w240-bottom">
						Submitting a Lead for Review
					</h4>
					
					<ul className="type-large-body type-narrow-line-height spacing-40">
						<li>
							We generate many thousands of Leads across the country and are always improving our automated Quality Check technologies as well as expanding our human Quality Assurance Team to catch Non-Billable Leads before our Clients are even aware of them.
						</li>
						<li>
							But, we also need you to help that process by submitting Leads for Review if you believe them to be genuinely Non-Billable according to our Service Terms.
						</li>
						<li>
							In your Lead Manager in mySD you can see all your Leads, including Billable and Non-Billable statuses. Click on a Lead to expand it to view detailed information, including the Lead Review section that allows you to click the “Submit for Review” link.
						</li>
					</ul>
					
					<ImageBox
						url="https://4098609.fs1.hubspotusercontent-na1.net/hubfs/4098609/Onboarding%20Screenshots/mySD-LeadManager-v12-Marketplace-Expanded-LeadReview.jpg"
						showPopup={props.showPopup}
						alt="Screenshot of an expanded Lead view with the Lead Review controls highlighted"
						extraClasses="spacing-40"
					/>
					
					<h4 className="type-large-subhead type-heavy spacing-24-bottom">
						A Lead is Eligible for Review if…
					</h4>
					
					<ul className="type-large-body type-narrow-line-height spacing-40-bottom">
						<li>
							The call was answered.
						</li>
						<li>
							The Lead was received less than 7 days ago.
						</li>
						<li>
							Call Recording was enabled at the time the Lead was received.
						</li>
					</ul>
					
					<CallOutBox
						headline="Learn More"
					>
						<ul className="type-large-body type-narrow-line-height">
							<li>
								Read our Help Center article <a href="https://support.servicedirect.com/lead-review" target="_blank" className="type-heavy">Submitting a Lead for Review</a>
							</li>
						</ul>
					</CallOutBox>
				</ExpandableSection>
				
				<QuizSection
					{...props}
					questions={[
						{
							name: "step-3-question-1",
							question: "What do I do if I get a call that I think is not Billable?",
							answers: [
								"Nothing",
								"Submit it for Review if the Lead is eligible",
							],
							correctAnswer: 2,
						},
						{
							name: "step-3-question-2",
							question: "What makes a Lead eligible for Review?",
							answers: [
								"The Call was answered",
								"The Lead was received less than 7 days ago",
								"Call Recording was enabled at the time Lead was received",
								"All of the above",
							],
							correctAnswer: 4,
						},
						{
							name: "step-3-question-3",
							question: "Is it possible to receive a Billable Lead that doesn’t turn into an appointment?",
							answers: [
								"Yes",
								"No",
							],
							correctAnswer: 1,
						},
						{
							name: "step-3-question-4",
							question: "Is this Lead Billable? The caller is looking for service in your Category but doesn’t book an appointment due to pricing:",
							answers: [
								"Billable Lead",
								"Non-Billable Lead",
							],
							correctAnswer: 1,
						},
						{
							name: "step-3-question-5",
							question: "Is this Lead Billable? The caller is an existing customer who has used your services:",
							answers: [
								"Billable Lead",
								"Non-Billable Lead",
							],
							correctAnswer: 2,
						},
						{
							name: "step-3-question-6",
							question: "Is this Lead Billable? The caller has a service need but the call is disconnected before an appointment was booked:",
							answers: [
								"Billable Lead",
								"Non-Billable Lead",
							],
							correctAnswer: 1,
						},
						{
							name: "step-3-question-7",
							question: "Is this Lead Billable? Caller has a service need but requests it for a zip code outside of your Service Area:",
							answers: [
								"Billable Lead",
								"Non-Billable Lead",
							],
							correctAnswer: 2,
						},
						{
							name: "step-3-question-8",
							question: "Is this Lead Billable? You miss the call:",
							answers: [
								"Billable Lead",
								"Non-Billable Lead",
							],
							correctAnswer: 1,
						},
						{
							name: "step-3-question-9",
							question: "Is this Lead Billable? Caller does not have a service need but you book an appointment anyway.",
							answers: [
								"Billable Lead",
								"Non-Billable Lead",
							],
							correctAnswer: 1,
						}
					]}
				/>
			</div>
		</div>
	);
};

export default StepThree;