import React, { useCallback, useEffect, useState } from 'react';
import Input from "../common/components/form_elements/Input";
import LoadingGif from '../common/components/LoadingGif'
import { getAffiliateForContractorId } from '../service/AffiliateService';
import ExternalBuyerService from "../service/ExternalBuyerService";
import ResourcesService from "../service/ResourcesService";
import ContractorService from "../service/ContractorService";

export default({
    clientName,
    handleChange,
    updateAdNetwork,
    lookupId,
    hasValidationError,
    validationErrorMessage,
    lookupContext,
    readOnly,
}) => {
    const [loading, setLoading] = useState(false);

    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [retrievedHubspotId, setRetrievedHubspotId] = useState(undefined);
    const [retrievedHubspotName, setRetrievedHubspotName] = useState(undefined);

    const [connectedContractorId, setConnectedContractorId] = useState(undefined);
    const [connectedContractorName, setConnectedContractorName] = useState(undefined);

    const [connectedLeadSourceId, setConnectedLeadSourceId] = useState(undefined);
    const [connectedLeadSourceName, setConnectedLeadSourceName] = useState(undefined);

    const [connectedExternalBuyerId, setConnectedExternalBuyerId] = useState(undefined);
    const [connectedExternalBuyerName, setConnectedExternalBuyerName] = useState(undefined);


    const handleHubSpotChange = (e) => {
        setLoading(true);
        handleChange(e);
        hubspotLookup(e.target.value);
    };

    const setLookupErrorState = (message) => {
        setHasError(true);
        setErrorMessage(message);

        setRetrievedHubspotId(undefined);
        setRetrievedHubspotName(undefined);
        setConnectedContractorId(undefined);
        setConnectedContractorName(undefined);
        setConnectedLeadSourceId(undefined);
        setConnectedLeadSourceName(undefined);
        setConnectedExternalBuyerId(undefined);
        setConnectedExternalBuyerName(undefined);
    };

    const hubspotLookup = useCallback((id) => {
        const resourcesService = new ResourcesService();
        const contractorService = new ContractorService();

        const hubspotIdLookups = [];
        hubspotIdLookups.push(resourcesService.getHubSpotCompanyData(id));
        hubspotIdLookups.push(contractorService.getContractorsForHubspotCompanyId(id));

        Promise.all(hubspotIdLookups).then(values => {
            const hubspotResponse = values[0];
            const contractors = values[1];

            if (contractors.length > 1) {
                const errorJsx = (
                    <>
                        <span className="type-heavy">Error:</span> Multiple Clients found for the same HubSpot Company ID. This must be fixed before continuing. The HubSpot Company ID can only belong to one SD Client.
                    </>
                )
                setLookupErrorState(errorJsx);
                setLoading(false);
                return;
            }

            const contractor = contractors[0];

            if (hubspotResponse.company) {
                if (hubspotResponse.company.status && response.company.status === "error") {
                    // HubSpot returned an error that our HubSpotApiClient passes back
                    // wrapped as a company object.
                    setLookupErrorState("No company with the specified ID was found.");
                    setLoading(false);
                    return;
                }

                setHasError(false);
                setErrorMessage('');
                setRetrievedHubspotId(id);
                setRetrievedHubspotName(hubspotResponse.company.properties.name.value);

                setConnectedContractorId(contractor?.contractor_id);
                setConnectedContractorName(contractor?.name);

                if (lookupContext === "lead-source") {
                    // Look for ELB
                    const externalBuyerService = new ExternalBuyerService();
                    if (contractor) {
                        externalBuyerService.getExternalBuyerForContractorId(contractor.contractor_id).then(({externalBuyer}) => {
                            setConnectedExternalBuyerId(externalBuyer?.externalBuyerId);
                            setConnectedExternalBuyerName(externalBuyer?.name);

                            setLoading(false);
                        });
                    }
                } else if (lookupContext === "external-buyer") {
                    if (contractor) {
                        getAffiliateForContractorId(contractor.contractor_id).then(affiliate => {
                            setConnectedLeadSourceId(affiliate?.ad_network_id);
                            setConnectedLeadSourceName(affiliate?.ad_network_name);

                            if (affiliate) {
                                updateAdNetwork([affiliate.ad_network_id]);
                            }

                            setLoading(false);
                        });
                    }
                }

                if (!contractor) {
                    setLoading(false);
                }
            }
        }).catch(err => {
            setLookupErrorState("No company with the specified ID was found.");
            setLoading(false);
        });
    }, [updateAdNetwork]);

    useEffect(() => {
        if (!retrievedHubspotId && lookupId && lookupId.length > 0) {
            setLoading(true);
            hubspotLookup(lookupId);
        }
    }, [updateAdNetwork]);

    const hubspotCompanyStatus = () => {
        const sdAdminUrl = process.env.REACT_APP_SD_ADMIN_URL || 'https://my.servicedirect.com/admin/';
        const hubSpotEnvironmentId = process.env.REACT_APP_HUBSPOT_ID

        if (!lookupId || lookupId.length === 0) {
            if (!clientName || clientName.length === 0) {
                return <></>;
            }
        }

        if (hasError) {
            return (
                <p className="type-alert">{errorMessage}</p>
            );
        }

        if (connectedContractorId) {
            return (
                <ul>
                    <li className="spacing-5-bottom">
                        <span className="type-heavy">HubSpot:</span>{' '}
                        <a href={`https://app.hubspot.com/contacts/${hubSpotEnvironmentId}/company/${retrievedHubspotId}`} target="_blank" rel="noopener noreferrer">
                            {retrievedHubspotName}
                        </a>
                    </li>
                    <li>
                        <span className="type-heavy">Client:</span>{' '}
                        <a href={`${sdAdminUrl}contractors/?contractor_id=${connectedContractorId}`}>
                            {connectedContractorName}
                        </a>
                    </li>
                    {connectedLeadSourceId && (
                        <li className="spacing-5-top">
                            <span className="type-heavy">Lead Source:</span>{' '}
                            <a href="/admin/lead-sources">
                                {connectedLeadSourceName}
                            </a>
                        </li>
                    )}
                    {connectedExternalBuyerId && (
                        <li className="spacing-5-top">
                            <span className="type-heavy">External Buyer:</span>{' '}
                            <a href="/admin/external-buyers">
                                {connectedExternalBuyerName}
                            </a>
                        </li>
                    )}
                </ul>
            )
        }

        return (
            <ul>
                <li>
                    <span className="type-heavy">New Client will be created:</span> {clientName}
                </li>
                {retrievedHubspotId && (
                    <li className="spacing-5-top">
                        <span className="type-heavy">HubSpot:</span>{' '}
                        <a href={`https://app.hubspot.com/contacts/${hubSpotEnvironmentId}/company/${retrievedHubspotId}`}
                           target="_blank" rel="noopener noreferrer">
                            {retrievedHubspotName}
                        </a>
                    </li>
                )}
            </ul>
        );
    };

    return (
        <>
            <Input
                type="text"
                id="hubspot_company_id"
                name="hubspot_company_id"
                onChange={(e) => handleHubSpotChange(e)}
                value={lookupId || ''}
                hasError={hasValidationError || hasError}
                errorMessage={validationErrorMessage}
                autocomplete="off"
                readOnly={readOnly}
            />
            <div className="spacing-10-top">
                {loading
                    ? <LoadingGif />
                    : hubspotCompanyStatus()
                }
            </div>
        </>
    );
};