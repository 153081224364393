import React, {useEffect, useState} from "react";
import MultiSelectList, {MultiSelectListSkeleton} from "./form_elements/MultiSelectList";
import ResourcesService from "../../service/ResourcesService";

const TimeZoneSelector = ({
    name,
    selectedOption,
    onChange,
    hasError,
    errorMessage,
}) => {
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const resourcesService = new ResourcesService();

        setIsLoading(true);
        resourcesService.getTimeZones()
            .then((timeZones) => {
                setOptions(timeZones);
                setIsLoading(false);
            });
    }, []);

    return isLoading ? <MultiSelectListSkeleton /> : (
        <MultiSelectList
            required={true}
            setGroups={true}
            hasGroups={true}
            useTextFilter={true}
            searchFromBeginning={false}
            label="Time Zone"
            name={name}
            emptyLabel="Please Select"
            options={options}
            selections={selectedOption ? [selectedOption] : null}
            onChange={onChange}
            hasError={hasError}
            errorMessage={errorMessage}
        />
    )
};

export default TimeZoneSelector;