import React from "react";

const PasswordStub = () => {
    return (
        <>
        <span className="type-small-body type-narrow-line-height type-heavy spacing-5-bottom">
            Password
        </span>
        <span className="type-small-body">
            This user will receive an email notification to set their password
        </span>
        </>
    )
};

export default PasswordStub;