import React, { useState, useEffect, useRef } from 'react'
import Input from '../../common/components/form_elements/Input'
import Button from '../../common/components/form_elements/Button'
import RadioGroup from '../../common/components/form_elements/RadioGroup'
import MultiSelectList from '../../common/components/form_elements/MultiSelectList'
import { getAffiliates } from '../../service/AffiliateService'
import Label from '../../common/components/form_elements/Label'
import ExternalBuyerService from '../../service/ExternalBuyerService'
import type { ExternalBuyer } from './types'

/**
 * Renders a modal for testing ping posts.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ExternalBuyer} props.externalBuyer - The external buyer object.
 * @param {Array} props.adNetworks - Array of ad network objects.
 * @returns {JSX.Element} The rendered TestPingPostModal component.
 */
export function TestPingPostModal({ externalBuyer, adNetworks: _adNetworks = [] }) {
    const [industry, setIndustry] = useState(externalBuyer?.industries[0]?.industryId)
    const [adNetwork, setAdNetwork] = useState('')
    const [adNetworks, setAdNetworks] = useState(_adNetworks)
    const [callerId, setCallerId] = useState('5555555555')
    const [zipCode, setZipCode] = useState('78701')
    const [useMpApi, setUseMpApi] = useState('0')
    const [requestMode, setRequestMode] = useState('ping')
    const modalRef = useRef()

    useEffect(() => {
        // get ad networks
        if (adNetworks.length === 0) {
            console.log('fetching ad networks')
            getAffiliates().then((resp) => {
                setAdNetworks(resp.affiliates)
            })
        }
    }, [adNetworks.length])

    useEffect(() => {
        if (adNetwork === '' && adNetworks.length > 0) {
            setAdNetwork(adNetworks[0].ad_network_id)
        }
    }, [adNetworks, adNetwork])

    const industryOptions = externalBuyer?.industries?.map((industry) => ({
        value: industry.industryId,
        text: industry.name,
    }))

    const adNetworkOptions = adNetworks.map((adNetwork) => ({
        value: adNetwork.ad_network_id,
        text: adNetwork.ad_network_name,
    }))

    const handleSendRequest = () => {
        const requestBody = {
            service_category_id: industry,
            ad_network_id: adNetwork,
            caller_id: callerId,
            zip_code: zipCode,
            use_mp_api: useMpApi,
        }
        requestBody.request_mode = requestMode
        requestBody.external_buyer_id = externalBuyer.externalBuyerId
        const externalBuyerService = new ExternalBuyerService()
        externalBuyerService.testPingPost(requestBody)
    }

    return (
        <div className="padding-30-full">
            <div className="popup__form__row spacing-20-bottom">
                <span>
                    ELB Integration Status:{' '}
                    <span className="type-heavy">{externalBuyer.status}</span>
                </span>
            </div>
            <div className="popup__form__row_grid spacing-20-bottom">
                <div className="popup__form__row_grid_child_span_2">
                    <div style={{ width: '100%' }}>
                        <MultiSelectList
                            label={
                                <div className="popup__form__row_grid_child_span_2">
                                    <Label
                                        modalContainerRef={modalRef}
                                        label="Industry"
                                        tooltipPosition="bottom"
                                        tooltipContent={
                                            <span className="type-small-body">
                                                The industry for the request.
                                            </span>
                                        }
                                    />
                                </div>
                            }
                            name="industry"
                            selections={[industry]}
                            options={industryOptions}
                            multiselect={false}
                            onChange={(values) => setIndustry(values[0])}
                        />
                    </div>
                </div>
                <div className="popup__form__row_grid_child_span_2">
                    <div style={{ width: '100%' }}>
                        <MultiSelectList
                            label={
                                <Label
                                    modalContainerRef={modalRef}
                                    label="Ad Network"
                                    tooltipPosition="bottom"
                                    tooltipContent={
                                        <span className="type-small-body">
                                            The ad network for the request.
                                        </span>
                                    }
                                />
                            }
                            useTextFilter={true}
                            name="adNetwork"
                            selections={[adNetwork]}
                            options={adNetworkOptions}
                            multiselect={false}
                            onChange={(values) => setAdNetwork(values[0])}
                        />
                    </div>
                </div>
            </div>
            <div className="popup__form__row_grid spacing-20-bottom">
                <div className="popup__form__row_grid_child_span_2">
                    <Input
                        label={
                            <Label
                                name="callerId"
                                modalContainerRef={modalRef}
                                label="Caller ID"
                                tooltipPosition="bottom"
                                tooltipContent={
                                    <span className="type-small-body">
                                        The phone number that will be used as the caller
                                        ID. Formatted as +1 then 10 digits with no other
                                        characters <br />
                                        Example: +15555555555
                                    </span>
                                }
                            />
                        }
                        type="text"
                        name="callerId"
                        value={callerId}
                        onChange={(event: KeyboardEvent) =>
                            setCallerId(event.target.value)
                        }
                    />
                </div>
                <div className="popup__form__row_grid_child_span_2">
                    <Input
                        label={
                            <Label
                                name="zipCode"
                                modalContainerRef={modalRef}
                                label="Zip Code"
                                tooltipPosition="bottom"
                                tooltipContent={
                                    <span className="type-small-body">
                                        The zip code for the request.
                                    </span>
                                }
                            />
                        }
                        type="text"
                        name="zipCode"
                        value={zipCode}
                        onChange={(event: KeyboardEvent) =>
                            setZipCode(event.target.value)
                        }
                    />
                </div>
            </div>
            <div className="popup__form__row_grid spacing-20-bottom">
                <div className="popup__form__row_grid_child_span_2">
                    <div style={{ width: '100%' }}>
                        <MultiSelectList
                            label={
                                <Label
                                    modalContainerRef={modalRef}
                                    label="Request Type"
                                    tooltipPosition="bottom"
                                    tooltipContent={
                                        <span className="type-small-body">
                                            Whether or not to use the `ping` or the `post`
                                            API endpoint for the ELB
                                        </span>
                                    }
                                />
                            }
                            name="requestMode"
                            selections={[requestMode]}
                            options={[
                                {
                                    value: 'ping',
                                    text: 'Ping',
                                },
                                {
                                    value: 'post',
                                    text: 'Post',
                                },
                            ]}
                            multiselect={false}
                            onChange={(values) => setRequestMode(values[0])}
                        />
                    </div>
                </div>
                <div className="popup__form__row_grid_child_span_2">
                    <RadioGroup
                        label={
                            <Label
                                modalContainerRef={modalRef}
                                label="Use Marketplace API"
                                tooltipPosition="bottom"
                                tooltipContent={
                                    <span className="type-small-body">
                                        If "No", the ELB integration methods will be
                                        tested directly with the fields in this form If
                                        <br />
                                        <br />
                                        "Yes", the Marketplace API will be used, which is
                                        a more complete end to end test. If you chose this
                                        option, all ELBs will be set to "in setup" except
                                        the target ELB, which will be set to "ping and
                                        post"
                                    </span>
                                }
                            />
                        }
                        options={[
                            { value: '1', label: 'Yes' },
                            { value: '0', label: 'No' },
                        ]}
                        name="useMpApi"
                        checkedOption={useMpApi}
                        isClearable={false}
                        onChange={(name, value) => setUseMpApi(value)}
                    />
                </div>
            </div>

            <div className="popup__form__row_grid spacing-20-bottom">
                <Button style={{ margin: 0 }} onClick={handleSendRequest}>
                    Send Request
                </Button>
            </div>
        </div>
    )
}
