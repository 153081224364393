import React from "react";

import Button from "../common/components/form_elements/Button";
import PropTypes from "prop-types";

/**
 * AdminHeaderBar Component
 *
 * This component is a simple layout wrapper for the headline plus button
 * header element on mySD Admin.
 *
 * @param {Object} - The props object
 * @param {string} props.headline - Text for the main page headline
 * @param {string} props.buttonLabel - Text for the button label
 * @param {Function} props.buttonClick - Function to handle clicks on the button
 * @returns {JSX.Element} The rendered component
 */
const AdminHeaderBar = ({
    headline,
    hasButton,
    buttonLabel,
    buttonClick
}) => {
    return (
        <div className="admin-view-headerbar">
            <div className="admin-view-headerbar__header">
                <h2 className="type-normal-headline type-heavy padding-20-bottom">
                    {headline}
                </h2>
            </div>
            {hasButton && (
                <div className="admin-view-headerbar__button">
                    <Button onClick={buttonClick}>
                        {buttonLabel}
                    </Button>
                </div>
            )}
        </div>
    );
};

AdminHeaderBar.propTypes = {
    headline: PropTypes.string.isRequired,
    hasButton: PropTypes.bool,
    buttonLabel: PropTypes.string,
    buttonClick: PropTypes.func,
};

AdminHeaderBar.defaultProps = {
    headline: '',
    hasButton: false,
    buttonLabel: '',
    buttonClick: null,
};

export default AdminHeaderBar;