import React, { useEffect, useState } from 'react';

import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';

import AdScheduler from '../../common/AdScheduler';
import AuthService from '../../service/AuthService';
import CampaignService from '../../service/CampaignService';
import ClickableDisabledButton from "../../common/ClickableDisabledButton";
import ContractorService from '../../service/ContractorService';
import HighlightedNote from '../../common/HighlightedNote';
import MultiSelectList from '../../common/components/form_elements/MultiSelectList';
import ResourcesService from '../../service/ResourcesService';
import Tooltip from '../../common/Tooltip';

const UpdateScheduleModal = (props) => {
	const [adSchedule, setAdSchedule] = useState();
	const [adScheduleText, setAdScheduleText] = useState();
	const [additionalCampaignSelections, setAdditionalCampaignSelections] = useState([]);
	const [additionalCampaignOptions, setAdditionalCampaignOptions] = useState([]);
	const [timeZone, setTimeZone] = useState(props.campaign.time_zone_id);
	const [timeZoneOptions, setTimeZoneOptions] = useState([]);
	const [timeZoneMissing, setTimeZoneMissing] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	
	useEffect(() => {
		const resourcesService = new ResourcesService();
		const contractorService = new ContractorService();
		const campaignService = new CampaignService();
		
		if (timeZoneOptions.length === 0) {
			resourcesService.getTimeZones()
				.then((timeZones) => setTimeZoneOptions(timeZones.map((tz) => {
					return {
						value: tz.value,
						text: `${tz.group} — ${tz.text}`,
					}
				})));
		}
		
		if (additionalCampaignOptions.length === 0) {
			contractorService.getCampaignNames()
				.then((data) => setAdditionalCampaignOptions(data.campaigns.filter(campaign => campaign.campaign_id !== props.campaign.campaign_id)
					.map((campaign) => {
						return {
							value: campaign.campaign_id,
							text: campaign.campaign_name,
						};
					})));
		}

		if (!props.campaign.time_zone_id) {
			contractorService.getPrimaryContact(props.campaign.contractor_id)
				.then((data) => {
					setTimeZone(data.primary_contact.time_zone_id);
				});
		}

		if (!adSchedule) {
			campaignService.getAdSchedule(props.campaign.campaign_id)
				.then((campaign_schedule) => {
					if (campaign_schedule) {
						setAdSchedule(campaign_schedule);
					} else {
						setAdSchedule({
							sunday: { start: '24h', end: '24h' },
							monday: { start: '24h', end: '24h' },
							tuesday: { start: '24h', end: '24h' },
							wednesday: { start: '24h', end: '24h' },
							thursday: { start: '24h', end: '24h' },
							friday: { start: '24h', end: '24h' },
							saturday: { start: '24h', end: '24h' },
						});
					}
				});
		}
		
	}, [
		props.campaign.campaign_id,
		props.campaign.contractor_id,
		props.campaign.time_zone_id,
		adSchedule,
		timeZoneOptions.length,
		additionalCampaignOptions.length
	]);
	
	const activeSchedulingMessage = (hours, percentage) => {
		if (hours && percentage) {
			if (percentage < 30) {
				return <span className="type-heavy type-red">only {hours} hours ({percentage}%)</span>;
			}
			
			return <span className="type-heavy">{hours} hours ({percentage}%)</span>;
		}
		
		return <span className="type-heavy">168 hours (100%)</span>;
	}
	
	return (
		<>
			{(props.status === 'enabled') ? (
				<p className="type-alert-text type-alert-text__success type-alert-text__no-margin-left type-small-body spacing-5-bottom">
					Campaign Enabled
				</p>
			) : (
				<>
					<p className="type-alert-text type-alert-text__alert type-alert-text__no-margin-left type-small-body spacing-5-bottom">
						Campaign Paused
					</p>
					<p className="type-normal-subhead type-heavy no-margin-top spacing-10-bottom">
						{props.campaign.campaign_name}
					</p>
					<div className="spacing-60-bottom">
						<button
							className="ui-normal-button button"
							onClick={props.updateCampaignPauseStatusModal}
						>
							Enable Campaign
						</button>
					</div>
				</>
			)}
			
			<p className="type-normal-subhead spacing-10-bottom">
				{(props.status === 'paused') && <>When Enabled,{' '}</>}
				<span className="type-heavy">{props.campaign.campaign_name}</span> has ads running {activeSchedulingMessage(props.campaign.active_schedule_hours, props.campaign.active_schedule_percentage)} of the week.
			</p>
			
			<p className="type-normal-body spacing-10">
				To control the time of day your Campaigns are actively advertising, please set the Campaign Schedule for each day below.
			</p>
			
			<HighlightedNote
				body={
					<span className="type-small-body">
						<span className="type-heavy">PLEASE NOTE:</span> Limiting the hours of your Campaign Schedule can result in fewer customer Leads. Occasionally Leads may occur outside the specified Schedules.
						<Tooltip
							content={<>
								<span className="type-small-body type-narrow-line-height type-force-newline spacing-10-bottom">
									The Campaign Schedule is the time of day during which your Campaigns are actively competing for Leads.
								</span>
								<span className="type-small-body type-narrow-line-height type-force-newline spacing-10-bottom">
									If you’d like the highest possible Lead Volume, then leave the Campaign Schedule 24/7. But, you can also limit it to specific times and days, resulting in lower{' '}
									<a
										href={
											AuthService.isMarketplace
												? "https://support.servicedirect.com/lead-volume"
												: "https://support.servicedirect.com/select/lead-volume"
										}
										className="type-heavy"
										target="_blank"
										rel="noopener noreferrer"
									>
										Lead Volume
									</a>.
								</span>
								<span className="type-small-body type-narrow-line-height type-force-newline spacing-10-bottom">
									If you’d like to completely Pause a Campaign, you can set that in the{' '}
									<a
										href={
											AuthService.isMarketplace
												? "https://support.servicedirect.com/pause-or-enable-campaign"
												: "https://support.servicedirect.com/select/pause-or-enable-campaign"
										}
										className="type-heavy"
										target="_blank"
										rel="noopener noreferrer"
									>
										Campaign Status
									</a>.
								</span>
								<span className="type-small-body type-narrow-line-height type-force-newline spacing-10-bottom">
									And, you can increase or decrease your desired Lead Volume by adjusting your{' '}
									<a
										href={
											AuthService.isMarketplace
												? "https://support.servicedirect.com/adjust-cost-per-lead"
												: "https://support.servicedirect.com/select/adjust-cost-per-lead"
										}
										className="type-heavy"
										target="_blank"
										rel="noopener noreferrer"
									>
										Cost Per Lead
									</a>
									.
								</span>
								<span className="type-small-body type-narrow-line-height type-force-newline">
									Examples of receiving a call outside of your Campaign Schedule may include:
									<ul className="spacing-10-top">
										<li className="spacing-5-bottom">a Lead saved the phone number they initially called you on and is calling back</li>
										<li>a Lead searched for your services during your active Campaign Schedule but only ended up calling later outside of your active Campaign Schedule</li>
									</ul>
								</span>
							</>}
							position="bottom"
						/>
					</span>
				}
				extraClass="spacing-40-bottom"
			/>
			
			<div className="spacing-40-bottom" style={{maxWidth: '440px'}}>
				<MultiSelectList
					label="Campaign Time Zone"
					name="timeZone"
					selections={[timeZone]}
					options={timeZoneOptions}
					useTextFilter={true}
					searchFromBeginning={false}
					onChange={(vals) => setTimeZone(vals[0])}
					hasError={timeZoneMissing}
					errorMessage={timeZoneMissing ? "A time zone is required to update the campaign schedule." : ''}
					dropdownLabel={timeZone ? null : "Please Select"}
				/>
			</div>

			<AdScheduler
				seedSchedule={adSchedule}
				context={'modal'}
				updateScheduleState={(scheduleData) => {
					let _state = _cloneDeep(adSchedule);
					if (!_isEqual(_state, scheduleData)) {
						_state = scheduleData;
						setAdSchedule(_state);
					}
				}}
				updateScheduleTextState={(scheduleText) => {
					if (adScheduleText !== scheduleText) {
						setAdScheduleText(scheduleText);
					}
				}}
				extraClass="spacing-40-bottom"
			/>
			
			{(additionalCampaignOptions.length > 0) && (
				<div style={{maxWidth: '580px'}}>
					<MultiSelectList
						label="Apply this Schedule to your other Campaigns"
						name="campaigns"
						selections={additionalCampaignSelections}
						multiselect={true}
						selectAllLabel="All Campaigns"
						allowDeselectAll={true}
						compileCustomLabel={(selections) => {
							if (selections.length === 0) {
								return "No Additional Campaigns Selected";
							}
							
							return `${selections.length} Additional Campaign${(selections.length === 1) ? '' : 's'} Selected`;
						}}
						options={additionalCampaignOptions}
						onChange={(vals) => setAdditionalCampaignSelections(vals)}
						extraClass="spacing-60-bottom"
					/>
				</div>
			)}
			
			<div className="ui-hide-full ui-hide-tablet">
				<div className="popup__form__row spacing-40-top-mobile">
					<div className="popup__form__cell popup__form__cell__100__mobile">
						<button className="button ui-normal-button ui-full-width-button">
							Set Campaign Schedule
						</button>
					</div>
				</div>
				<div className="popup__form__row spacing-20-top-mobile">
					<div className="popup__form__cell popup__form__cell__100__mobile type-centered">
						<span role="button"
						  	  className="type-small-body type-heavy type-blue"
						      onClick={() => props.updateModalContent()}
						>
							Cancel
						</span>
					</div>
				</div>
			</div>
			<div className="ui-hide-mobile">
				<div className="popup__form__gray-bar padding-24 spacing-30-top no-margin-bottom">
					<div className="popup__form__row popup__form__row__slam-right">
						<div className="popup__form__cell">
							<span role="button"
								  className="type-small-body type-heavy type-blue"
								  onClick={() => props.updateModalContent()}
							>
								Cancel
							</span>
						</div>
						<div className="popup__form__cell">
							<ClickableDisabledButton
								disabled={submitting || !timeZone}
								title={timeZone ? '' : "A time zone is required to update the campaign schedule."}
								onClick={() => {

									if (!timeZone) {
										setTimeZoneMissing(true);
									} else {
										setSubmitting(true);
										setTimeZoneMissing(false);
										props.submitPutSchedule(
											props.campaign.campaign_id,
											{
												time_zone_id: timeZone,
												ad_schedule_data: adSchedule,
												ad_schedule_text: adScheduleText,
												additional_campaigns: additionalCampaignSelections,
											},
											() => {
												setSubmitting(false);
												props.updateModalContent();
											},
										);
									}
								}}
								disabledClickHandler={() => {
									if (!timeZone) {
										setTimeZoneMissing(true);
									}
								}}
							>
								Set Campaign Schedule
							</ClickableDisabledButton>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default UpdateScheduleModal;