import axios from 'axios'
import post, { get, put } from '../Requests'
import qs from 'query-string'

const cancelSignal = axios.CancelToken.source()

export const getAffiliates = (query) => {
    let endpoint = `ad_networks/admin/affiliate`
    if (query) {
        endpoint += `?${query}`
    }
    return get(endpoint, cancelSignal.token).then((response) => response.data)
}

export const getAffiliateForContractorId = (id) => {
    const endpoint = `ad_networks/admin/affiliate_for_contractor/${id}`;

    return get(endpoint, cancelSignal.token).then((response) => response.data.affiliate);
};

export const saveAffiliateUpdates = (id, updates) => {
    const endpoint = `ad_networks/admin/affiliate/${id}`
    return put(endpoint, { updates }, cancelSignal.token)
}

export const saveNewAffiliate = (affiliate) => {
    const endpoint = `ad_networks/admin/affiliate`
    return post(endpoint, affiliate, cancelSignal.token)
}

export const getServiceCategoryRulesGrouped = (query) => {
    let endpoint = `ad_networks/admin/service_category_rules/grouped`
    if (query) {
        endpoint += `?${query}`
    }
    return get(endpoint, cancelSignal.token).then((response) => response.data)
}

export const getServiceCategoryRules = (query) => {
    let endpoint = `ad_networks/admin/service_category_rules`
    if (query) {
        endpoint += `?${query}`
    }
    return get(endpoint, cancelSignal.token).then((response) => response.data)
}

export const getServiceCategoryRuleTypes = () => {
    let endpoint = `ad_networks/admin/service_category_rules_types`
    return get(endpoint, cancelSignal.token).then((response) => response.data)
}

export const saveServiceCategoryRules = (rules) => {
    let endpoint = `ad_networks/admin/service_category_rules`
    return put(endpoint, { rule_updates: rules })
}

export const connectCampaigns = (body) => {
    return post('ad_networks/admin/affiliate/connect', body)
}

export const saveDIDNumbers = (body) => {
    return put('ad_networks/admin/did', body)
}

export const getDIDNumbers = (query) => {
    let url = 'ad_networks/admin/did'
    if (query) {
        const q = qs.stringify(query)
        url = `${url}?${q}`
    }
    return get(url).then((response) => response.data)
}

export const getDIDNumbersUI = () => {
    return get('ad_networks/admin/did/ui').then((response) => response.data)
}
