import React, {useEffect, useState} from "react";

import MultiSelectList from "../../../common/components/form_elements/MultiSelectList";
import PopupFormGroup from "../../../common/popup_form/PopupFormGroup";
import PopupFormRow from "../../../common/popup_form/PopupFormRow";
import PopupFormCell from "../../../common/popup_form/PopupFormCell";
import PasswordStub from "./PasswordStub";
import Input from "../../../common/components/form_elements/Input";
import TimeZoneSelector from "../../../common/components/TimeZoneSelector";
import ContractorService from "../../../service/ContractorService";
import {errorHandler} from "../../../Requests";

const NewAffiliateUserPane = ({
    affiliateUserState,
    setAffiliateUserState,
    errorMessageState,
}) => {
    const [affiliateOptions, setAffiliateOptions] = useState([]);

    useEffect(() => {
        const contractorService = new ContractorService();

        contractorService.getAffiliates()
            .then((response: Object) => {
                setAffiliateOptions(response.map(affiliate => { return { text: affiliate.name, value: affiliate.contractor_id }}));
            })
            .catch(errorHandler);
    }, []);

    return (
        <PopupFormGroup>
            <PopupFormRow
                fullWidthOnMobile={true}
            >
                <PopupFormCell>
                    <MultiSelectList
                       label={"Affiliate Company Name"}
                       name={"affiliate-company-name"}
                       options={affiliateOptions}
                       required={true}
                       emptyLabel="Please Select"
                       selections={affiliateUserState.company ? [affiliateUserState.company] : null}
                       onChange={(val) => {
                           const _stateClone = {...affiliateUserState};
                           _stateClone.company = val[0];
                           setAffiliateUserState(_stateClone);
                       }}
                       hasError={errorMessageState.affiliateCompany.length > 0}
                       errorMessage={errorMessageState.affiliateCompany}
                       useTextFilter={true}
                   />
               </PopupFormCell>
           </PopupFormRow>
           <PopupFormRow
               fullWidthOnMobile={true}
           >
               <PopupFormCell>
                   <Input
                       type="text"
                       name="email"
                       label="Email *"
                       subLabel="(will also be their Username)"
                       value={affiliateUserState.email}
                       onChange={(e) => {
                           const _stateClone = {...affiliateUserState};
                           _stateClone.email = e.target.value;
                           setAffiliateUserState(_stateClone);
                       }}
                       hasError={errorMessageState.affiliateEmail.length > 0}
                       errorMessage={errorMessageState.affiliateEmail}
                   />
               </PopupFormCell>
               <PopupFormCell>
                    <PasswordStub />
               </PopupFormCell>
           </PopupFormRow>
           <PopupFormRow
               fullWidthOnMobile={true}
           >
               <PopupFormCell>
                   <Input
                       type="text"
                       name="name"
                       label="Name *"
                       required={true}
                       value={affiliateUserState.name}
                       onChange={(e) => {
                           const _stateClone = {...affiliateUserState};
                           _stateClone.name = e.target.value;
                           setAffiliateUserState(_stateClone);
                       }}
                       hasError={errorMessageState.affiliateName.length > 0}
                       errorMessage={errorMessageState.affiliateName}
                   />
               </PopupFormCell>
               <PopupFormCell>
                   <Input
                       type="text"
                       name="title"
                       label="Title / Role"
                       value={affiliateUserState.title}
                       onChange={(e) => {
                           const _stateClone = {...affiliateUserState};
                           _stateClone.title = e.target.value;
                           setAffiliateUserState(_stateClone);
                       }}
                   />
               </PopupFormCell>
           </PopupFormRow>
           <PopupFormRow
               fullWidthOnMobile={true}
           >
               <PopupFormCell>
                   <Input
                       type="text"
                       name="phone"
                       label="Phone"
                       value={affiliateUserState.phone}
                       onChange={(e) => {
                           const _stateClone = {...affiliateUserState};
                           _stateClone.phone = e.target.value;
                           setAffiliateUserState(_stateClone);
                       }}
                   />
               </PopupFormCell>
               <PopupFormCell>
                    <TimeZoneSelector
                        name="affiliate-time-zone"
                        selectedOption={affiliateUserState.timeZone}
                        onChange={(val) => {
                            const _stateClone = {...affiliateUserState};
                            _stateClone.timeZone = val[0];
                            setAffiliateUserState(_stateClone);
                        }}
                        hasError={errorMessageState.affiliateTimeZone.length > 0}
                        errorMessage={errorMessageState.affiliateTimeZone}
                    />
               </PopupFormCell>
           </PopupFormRow>
       </PopupFormGroup>

   );
};

export default NewAffiliateUserPane;