import React from 'react'
import PropTypes from 'prop-types'

import Tooltip from '../../Tooltip'

const Label = (props) => {
    let labelClass =
        'type-small-body type-narrow-line-height type-heavy spacing-5-bottom'
    const inputRef = props.inputRef || React.createRef()

    if (props.disabled) {
        labelClass += ' type-grey-2'
    }

    if (props.hasError) {
        labelClass += ' type-alert'
    }

    if (props.className) {
        labelClass += ` ${props.className}`
    }

    if (props.onClick) {
        labelClass += ` pointer`
    }

    return (
        <label
            onClick={props.onClick}
            ref={inputRef}
            htmlFor={props.name}
            className={labelClass}
            title={props.title}
            style={props.style}
        >
            {props.label} {props.required ? '*' : ''}{' '}
            {props.required || props.addOn || ''}
            {props.tooltipContent ? (
                <>
                    <span>&nbsp;</span>
                    <Tooltip
                        content={props.tooltipContent}
                        modalContainerRef={props.modalContainerRef}
                        position={props.tooltipPosition}
                    />
                </>
            ) : null}
        </label>
    )
}

export default Label

Label.propTypes = {
    name: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    className: PropTypes.string,
    disabled: PropTypes.bool,
    hasError: PropTypes.bool,
    inputRef: PropTypes.object,
    required: PropTypes.bool,
    addOn: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    modalContainerRef: PropTypes.object,
    tooltipPosition: PropTypes.string,
    title: PropTypes.string,
    style: PropTypes.object,
}
