import React from 'react';

const AffiliateInsightsApp = (props) => {
    return (
        <section>
            AIP Placeholder
        </section>
    );
};

export default AffiliateInsightsApp;