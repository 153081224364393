import React from "react";

import PopupFormGroup from "../../../common/popup_form/PopupFormGroup";
import PopupFormRow from "../../../common/popup_form/PopupFormRow";
import PopupFormCell from "../../../common/popup_form/PopupFormCell";
import PasswordStub from "./PasswordStub";
import Input from "../../../common/components/form_elements/Input";
import RadioGroup from "../../../common/components/form_elements/RadioGroup";
import TimeZoneSelector from "../../../common/components/TimeZoneSelector";

const newAdminUserPane = ({
    adminUserState,
    setAdminUserState,
    errorMessageState,
}) => {
    return (
        <PopupFormGroup>
            <PopupFormRow
                fullWidthOnMobile={true}
            >
                <PopupFormCell>
                    <RadioGroup
                        name={"admin-access-level"}
                        label={"Admin Access Level *"}
                        options={[
                            { label: "Admin", value: "admin" },
                            { label: "Super User", value: "super-user" },
                            { label: "Lead Reviewer", value: "lead-reviewer" },
                        ]}
                        checkedOption={adminUserState.accessLevel}
                        onChange={(name, val) => {
                            const _stateClone = {...adminUserState};
                            _stateClone.accessLevel = val;
                            setAdminUserState(_stateClone);
                        }}
                        isClearable={false}
                        hasError={errorMessageState.adminAccessLevel.length > 0}
                        errorMessage={errorMessageState.adminAccessLevel}
                    />
                </PopupFormCell>
            </PopupFormRow>
            <PopupFormRow
                fullWidthOnMobile={true}
            >
                <PopupFormCell>
                    <Input
                        type={"text"}
                        name={"admin-email"}
                        label="Email *"
                        subLabel="(will also be their Username)"
                        value={adminUserState.email}
                        onChange={(e) => {
                            const _stateClone = {...adminUserState};
                            _stateClone.email = e.target.value;
                            setAdminUserState(_stateClone);
                        }}
                        hasError={errorMessageState.adminEmail.length > 0}
                        errorMessage={errorMessageState.adminEmail}
                    />
                </PopupFormCell>
                <PopupFormCell>
                    <PasswordStub />
                </PopupFormCell>
            </PopupFormRow>
            <PopupFormRow
                fullWidthOnMobile={true}
            >
                <PopupFormCell>
                    <TimeZoneSelector
                        name="admin-time-zone"
                        selectedOption={adminUserState.timeZone}
                        onChange={(val) => {
                            const _stateClone = {...adminUserState};
                            _stateClone.timeZone = val[0];
                            setAdminUserState(_stateClone);
                        }}
                        hasError={errorMessageState.adminTimeZone.length > 0}
                        errorMessage={errorMessageState.adminTimeZone}
                    />
                </PopupFormCell>
            </PopupFormRow>
        </PopupFormGroup>
    );
};

export default newAdminUserPane;